import React, { useEffect } from 'react';
import { useDevice } from '../screens/common/DeviceContext';
import { Text } from 'react-native';
import { BLXSpecialBitSwitchListItem } from '../screens/blx/common/BLXSpecialBitSwitchListItem';
import { useBlxSpecialBits } from '../screens/blx/protocol/useBlxSpecialBits';
import { useTranslation } from 'react-i18next';
import { isset } from '../screens/jsutils';
import { Spacer } from '../screens/ui/RTBUI';

/**
 * temporary locid settings screen.
 * Full settings will be available when firmare implements jsonrpc settings protocol.
 * @returns 
 */
export function LocIDSettingsScreen() {
  const device = useDevice();
  const { specialBits, specialBitsRead, specialBitsWrite } = useBlxSpecialBits(device);
  const { t } = useTranslation();

  useEffect(() => {
    if (device) {
      specialBitsRead();
    }
  }, [device]);

  return (<>
    <Spacer />

    <BLXSpecialBitSwitchListItem
      title={t("Enable LOC.id functionality")}
      subtitle="B[3]"
      specialBits={specialBits}
      specialBitsWrite={specialBitsWrite}
      register={1}
      bitIndex={0x03}
      topDivider
      bottomDivider 
      />

    <Spacer />

    <BLXSpecialBitSwitchListItem
      title={t("Automatic APS call in near range")}
      subtitle="A[B]"
      register={0}
      bitIndex={0xB}
      specialBits={specialBits}
      specialBitsWrite={specialBitsWrite}
      // topDivider
      bottomDivider
      disabled={!isset(specialBits[1], 0x03)} 
      />
  </>);
}
