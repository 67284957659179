import React from 'react';
import axios from 'axios';
import { InputAdornment, IconButton, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, TextField } from '@material-ui/core';
import { Alert } from '../screens/ui/Alert';
import { API_BASE_URL } from '../App';
import RefreshIcon from '@material-ui/icons/Refresh';

export function UserEditModal({ user, open, onClose }) {

  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState(null);
  const [emailError, setEmailError] = React.useState(null);
  const [firstName, setFirstName] = React.useState(null);
  const [firstNameError, setFirstNameError] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [lastNameError, setLastNameError] = React.useState(null);
  const [roles, setRoles] = React.useState([]);
  const [password, setPassword] = React.useState(null);

  const handleClose = () => {
    setEmail(null)
    setFirstName(null)
    setLastName(null)
    setRoles(null)
    setPassword(null)

    onClose();
  };

  const handleSave = async() => {
    var roleNames = roles.filter(e => e.assigned === true).map(e => e.name);
    console.log("saving roleNames: ", roleNames)

    let userId = user?.id;

    if(!userId) {
      if((firstName ?? '').length === 0) {
        setFirstNameError("Must not be empty")
        return
      }
      else {
        setFirstNameError(null)
      }
      if((lastName ?? '').length === 0) {
        setLastNameError("Must not be empty")
        return
      }
      else {
        setLastNameError(null)
      }
      if((email ?? '').length === 0) {
        setEmailError("Must not be empty")
        return
      }
      else {
        setEmailError(null)
      }

      let newUser = {
        email,
        firstName,
        lastName,
        password
      }

      try {
        const createUserRes = await axios.post(`${API_BASE_URL}/admin/users`, newUser);
        console.log("createUserRes:", createUserRes, createUserRes.data);

        userId = createUserRes.data.id
      }
      catch (err) {
        console.error(err);
        if(err.response) {
          console.error(err.response);
          Alert.alert("Error creating user", JSON.stringify(err.response.data));
        }

        return
      }
    }

    try {
      const setRolesRes = await axios.post(`${API_BASE_URL}/admin/users/${userId}/realm_roles`, {
        roles: roleNames
      });
      console.log("setRolesRes:", setRolesRes, setRolesRes.data);
    }
    catch (err) {
      console.error(err);
      Alert.alert("Error setting roles", err.message);
      return
    }

    handleClose()
  };

  const loadUserRoles = async () => {
    console.log("loadUserRoles")

    setLoading(true);

    try {
      const availableRealmRolesRes = await axios.get(`${API_BASE_URL}/admin/realm_role_permissions`);
      console.log("availableRealmRolesRes:", availableRealmRolesRes);

      const realmRolePermissions = availableRealmRolesRes.data.write;

      let userRealmRoles = []
      if(user?.id) {
        const userRolesRes = await axios.get(`${API_BASE_URL}/admin/users/${user.id}/realm_roles`);
        console.log("get roles res:", userRolesRes.data);

        userRealmRoles = userRolesRes.data;
      }

      const newRoles = realmRolePermissions
        .map(role => {
          const assigned = userRealmRoles.find(realmRole => role.name === realmRole.name) !== undefined;
          return {
            ...role,
            assigned: assigned
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      console.log("setting Roles state:", newRoles);
      setRoles(newRoles);
    }
    catch (err) {
      Alert.alert(`Error loading roles: ${err.message}`);
    }
    finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    console.log("user changed");
    if (!user)
      return;

    setFirstName(user?.firstName)
    setLastName(user?.lastName)
    setEmail(user?.email)
    loadUserRoles();
    if(user.id === undefined) {
      onRegenPasswordClick()
    }
  }, [user]);

  const onRoleCheckboxClick = async (event, role) => {
    console.log("handleChange:", event.target.checked, "role:", role);

    setRoles(old => {
      var newRoles = [...old]
      const idx = newRoles.findIndex(e => e.name === role.name);
      console.log("idx: ", idx)
      if(idx >= 0) {
        newRoles[idx].assigned = event.target.checked
      }
      return newRoles
    })
  };

  const onRegenPasswordClick = async() => {
    const randPw = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10); 
    setPassword(randPw)
  }

  const onResetPasswordClick = async() => {
    let userId = user?.id;
    try {
      const res = await axios.post(`${API_BASE_URL}/admin/users/${userId}/reset-password`);
      console.log("createUserRes:", res, res.data);
      Alert.alert("Success", `A password reset email was sent to '${user?.email}'`);
    }
    catch(err) {
      console.error(err)
      Alert.alert("Password reset error", JSON.stringify(err));
    }
  }

  React.useEffect(() => {
    console.log("roles useEffect", roles);
  }, [roles]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        {user?.id ? 'Edit ' + user?.username : "New User"}
      </DialogTitle>

      {/* {loading && (
              <CircularProgress />
            )} */}

      <DialogContent dividers>
        <form>
          <Grid container spacing={3}>
            <Grid item>
            <TextField
              label="First name"
              value={firstName}
              onChange={evt => setFirstName(evt.target.value)}
              error={firstNameError !== null}
              helperText={firstNameError}
              style={{width: 300}}
              disabled={user?.id !== undefined}
              required
              />
            </Grid>
            <Grid item>
            <TextField xs={12}
              label="Last name"
              value={lastName}
              onChange={evt => setLastName(evt.target.value)}
              error={lastNameError !== null}
              helperText={lastNameError}
              style={{width: 300}}
              disabled={user?.id !== undefined}
              required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                value={email}
                onChange={evt => setEmail(evt.target.value)}
                error={emailError !== null}
                helperText={emailError}
                style={{width: 300}}
                disabled={user?.id !== undefined}
                required
                />
            </Grid>
            {user?.id && (
              <Grid item xs={12}>
                <Button onClick={onResetPasswordClick} color="orange">
                  Reset Password
                </Button>
              </Grid>
            )}
            {!user?.id && (
              <Grid item xs={12}>
                <TextField
                  label="Password (temporary)"
                  autoComplete={false}
                  // type="password"
                  value={password}
                  onChange={evt => setPassword(evt.target.value)}
                  disabled={user?.id !== undefined}
                  autoComplete="new-password"
                  style={{width: 300}}
                  // fullWidth
                  required
                  placeholder="Password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="regen password"
                          onClick={onRegenPasswordClick}
                        >
                          <RefreshIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  />
              </Grid>
            )}
          </Grid>

          <Divider style={{marginTop: 30, marginBottom: 30}} />

          <h3>Permissions</h3>

          <FormControl>
            <FormGroup>
              {roles?.map(role => (
                <FormControlLabel
                  control={<Checkbox
                    checked={role.assigned}
                    onChange={event => onRoleCheckboxClick(event, role)} />}
                  label={role.description ? `${role.description} (${role.name})` : role.name} />
              ))}
            </FormGroup>
          </FormControl>

          {/* <pre>
            Roles: {JSON.stringify(roles, null, 4)}
            user: {JSON.stringify(user, null, 4)}
          </pre> */}
        </form>

      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="gray">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
