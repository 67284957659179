import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from "../ui/Alert";
import { ListItem, Text } from "react-native-elements";
import { DeviceContext } from '../common/DeviceContext';
import { DescriptionListItem, NumericInputItem, SectionHeader, Spacer, SwitchListItem, useStatusBarActivity } from '../ui/RTBUI';
import { SelectItem } from "../SelectItem";
import { BLXSpecialBitSwitchListItem } from './common/BLXSpecialBitSwitchListItem';
import { BLXDebugSpecialBits, DebugView } from './common/DebugView';
import { useBlxSpecialBits } from './protocol/useBlxSpecialBits';
import { BLXScreenContainer } from './BLXScreenContainer';
import { useNavigation } from '@react-navigation/core';
import { isset } from '../jsutils';

export function BLXWalkScreen() {
  const { device } = React.useContext(DeviceContext);
  const { t } = useTranslation();
  const { specialBits, specialBitsRead, specialBitsWrite } = useBlxSpecialBits(device);
  const { setActivity } = useStatusBarActivity();
  const navigation = useNavigation()

  const [timerAudio, setTimerAudio] = React.useState(null);
  const [toneVariant, setToneVariant] = React.useState(null);

  const load = async () => {
    if (device) {
      try {
        await specialBitsRead();

        const timerAudioRes = await device.jsonRpc("blx.getTimerAudio");
        setTimerAudio(timerAudioRes.value);

        const toneVariantRes = await device.jsonRpc("blx.getWalkTone");
        console.log("toneVariantRes:", toneVariantRes);
        setToneVariant(toneVariantRes.walkTone);
        //setTimerAudio(timerAudioRes.value)
      }
      catch (err) {
        Alert.alert(t("Device error"), err.message);
      }
    }
  };

  const sendTimerAudio = async (val) => {
    try {
      await device.jsonRpc("blx.setTimerAudio", { value: val });
      await load();
    }
    catch (err) {
      Alert.alert(t("Device error"), err.message);
    }
  };

  React.useEffect(() => {
    load();
    return () => { };
  }, [device]);

  if (!device) {
    return (<Text>no device</Text>);
  }

  return (
    <BLXScreenContainer onReload={load}>
      <SectionHeader>
        {t('Volume profiles')}
      </SectionHeader>

      <ListItem
        title={device.isHongkong() ? t("Day") : t("PED")}
        subtitle={device?.isHongkong() ? t("Mode 1") : t("Default profile")}
        subtitleStyle={{ color: 'gray' }}
        onPress={() => {
          if(navigation.push)
            navigation.push("BLXToneProfile", { mode: "day", tone: "FT", title: t("PED volume profile") })
          else
            navigation.navigate("BLXToneProfile", { mode: "day", tone: "FT", title: t("PED volume profile") })
        }}
        leftIcon={{
          type: "font-awesome-5", color: 'gray', style: { width: 30, textAlign: 'center' },
          name: (device.isHongkong() ? "sun" : "traffic-light"),
          solid: true
        }}
        chevron
        bottomDivider
        topDivider />

      <ListItem
        title={device?.isHongkong() ? "Night" : "APS / LOC.id"}
        subtitle={device?.isHongkong() ? t("Mode 2") : t("Nearby assistive pedestrian")}
        subtitleStyle={{ color: 'gray' }}
        onPress={() => navigation.navigate("BLXToneProfile", { mode: "night", tone: "FT", title: t("APS volume profile") })}
        leftIcon={{
          type: "font-awesome-5", color: 'gray', style: { width: 30, textAlign: 'center' },
          name: 'blind',
          name: (device.isHongkong() ? "moon" : "blind"),
          solid: true
        }}
        chevron
        bottomDivider />

      <SectionHeader>
        {t('Voice & Melody')}
      </SectionHeader>

      {device?.isHongkong() && (<>
        <SelectItem
          title={t('Tone variant')}
          items={[
            { label: t('0'), value: 0 },
            { label: t('1'), value: 1 },
            { label: t('2'), value: 2 },
          ]}
          onValueChange={(val) => {}}
          topDivider
          bottomDivider
        />

        <Text style={{color: '#f08400', margin: 10}}>
          (Switch tone variant / 52: CMD_FADER_LS:{'\n'}Wär cool, wenn wir die für PT/FT einzeln konfigurieren können, dann kann ich's hier rein machen 
          und es wär konsistenter mit den NA-Varianten.)
          </Text>
      </>)}

      {(device?.isNorthAmerica() || device?.isQuebec()) && (<>
        <SelectItem
          title={t('Tone variant')}
          items={[
            ...[
              { label: t('Chirp voice'), value: 'chirp_voice' },
              { label: t('Cuckoo voice'), value: 'cuckoo_voice' },
              { label: t('Canadian melody'), value: 'canadian_melody' },
              { label: t('Rapid tone 1'), value: 'rapid_tone_1' },
              { label: t('Rapid tone 2'), value: 'rapid_tone_2' },
              { label: t('Rapid tone 3'), value: 'rapid_tone_3' },
            ],
            ... (device?.isNorthAmerica() ? [
              { label: t('Walk sign is on for all crossings'), value: 'all_crossing' },
              { label: t('Walk sign is on to cross'), value: 'single_crossing' },
            ] : [])
          ]}
          value={toneVariant}
          onValueChange={async (val) => {
            console.log("val: ", val);
            setActivity(true);
            try {
              await device.jsonRpc("blx.setWalkTone", { walkTone: val });
              setToneVariant(val);
            }
            catch (err) {
              Alert.alert(t("Device error"), err.message);
            }
            finally {
              setActivity(false);
            }
          }}
          topDivider
          bottomDivider 
          />

        <NumericInputItem
          title={"Maximum duration"} // 5-15, 16=off
          value={timerAudio}
          onValueSubmit={sendTimerAudio}
          placeholder={t("disabled")}
          unit="s"
          valueType="number"
          bottomDivider
          minValue={5}
          maxValue={15}
          nullValue={16} 
          />
      </>)}


      {device?.isHongkong() && <>
        <SectionHeader>Speakers</SectionHeader>
        <SwitchListItem
          title="Front speaker output"
          topDivider
        />
        <SelectItem
          title={t('Volume adjustment')}
          items={[
            { label: t('0 dB'), value: 0 },
            { label: t('1 dB'), value: 1 },
            { label: t('2 dB'), value: 2 },
            { label: t('...'), value: 3 },
            { label: t('15 dB'), value: 15 },
          ]}
          onValueChange={(val) => {}}
          topDivider
          bottomDivider
        />

        <Spacer />
        <SwitchListItem 
          title="Side speakers output"
          topDivider
          bottomDivider
        />

        <SelectItem
          title={t('Volume adjustment')}
          items={[
            { label: t('0 dB'), value: 0 },
            { label: t('1 dB'), value: 1 },
            { label: t('2 dB'), value: 2 },
            { label: t('...'), value: 3 },
            { label: t('15 dB'), value: 15 },
          ]}
          onValueChange={(val) => {}}
          bottomDivider
        />
      </>}

      {(device?.isNorthAmerica() || device?.isQuebec()) && <>
        <SectionHeader>
          {t("Speakers")}
        </SectionHeader>

        <BLXSpecialBitSwitchListItem
          title={t("BLX Speaker output")}
          subtitle="B[D]"
          specialBits={specialBits}
          specialBitsWrite={specialBitsWrite}
          register={1}
          bitIndex={0xD}
          topDivider
          bottomDivider />
        <BLXSpecialBitSwitchListItem
          title={t("PiT Speaker output")}
          subtitle="!B[E]"
          specialBits={specialBits}
          specialBitsWrite={specialBitsWrite}
          register={1}
          bitIndex={0xE}
          invert={true}
          bottomDivider 
          />
      </>}

      <SectionHeader>
        {t("Other")}
      </SectionHeader>
      <BLXSpecialBitSwitchListItem
        title={t("Enable for PED calls")}
        subtitle="!B[2]"
        specialBits={specialBits}
        specialBitsWrite={specialBitsWrite}
        register={1}
        bitIndex={0x2}
        invert={true}
        topDivider
        bottomDivider 
        />

      <DescriptionListItem
        text={t("If set, walk & clearance signals are also played back after regular PED calls.")} />

      {device?.isNorthAmerica() && ( <>
        <BLXSpecialBitSwitchListItem
          title={t("Keep locator tone enabled")}
          subtitle="B[4]"
          specialBits={specialBits}
          specialBitsWrite={specialBitsWrite}
          register={1}
          bitIndex={0x4}
          invert={true}
          topDivider
          bottomDivider
          />
      </>)}

      {device?.isQuebec() && ( <>
        <SectionHeader>
          {t("Ping pong")}
        </SectionHeader>

        {/* inserts "delays" between voice outputs,
        so that the BLX B can output voice when BLX A is silent */}
        <BLXSpecialBitSwitchListItem
          title={t("Enable Ping Pong")}
          subtitle="B[4]"
          specialBits={specialBits}
          specialBitsWrite={specialBitsWrite}
          register={1}
          bitIndex={0x4}
          topDivider
          bottomDivider
        />
        {/* <DescriptionListItem text={t("If enabled, walk and clear tones are delayed...")} /> */}

        {/* inserts initial delay (used for BLX b) */}
        <BLXSpecialBitSwitchListItem
          title={t("Delay tone output")}
          subtitle="B[7]"
          specialBits={specialBits}
          specialBitsWrite={specialBitsWrite}
          register={1}
          bitIndex={0x7}
          disabled={!isset(specialBits[1], 0x4)}
          bottomDivider
        />
      </>)}

      <Spacer />
      <Spacer />

      <DebugView>
        <Spacer />
        <Text>DEBUG:</Text>
        <BLXDebugSpecialBits specialBits={specialBits} />
      </DebugView>

    </BLXScreenContainer>
  );
}
