import React from 'react';
import axios from 'axios';
import { Button, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Alert } from '../screens/ui/Alert';
import { AuthContext, API_BASE_URL, keycloak } from '../App';
import { UserEditModal } from "./UserEditModal";
import PersonAddIcon from '@material-ui/icons/PersonAdd';

export function AdminView() {
  const [userEditOpen, setUserEditOpen] = React.useState(false);
  const [userEditUser, setUserEditUser] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const { auth } = React.useContext(AuthContext);

  const [userEmailSearchText, setUserEmailSearchText] = React.useState("")

  const onUserSearchFieldChange = async (text) => {
    if (text.length > 2) {
      try {
        const usersRes = await axios.get(`${API_BASE_URL}/admin/users`, {
          params: { query: text },
        });
        console.log("usersRes:", usersRes, usersRes.data);
        setUsers(usersRes.data);
      }
      catch (err) {
        console.error(err);
        Alert.alert("Error fetching users", err.message);
      }
    }
    else {
      setUsers([]);
    }
  };

  const onUserEmailSearchSubmit = async() => {
    try {
      const usersRes = await axios.get(`${API_BASE_URL}/admin/users`, {
        params: { email: userEmailSearchText },
      });
      console.log("usersRes:", usersRes, usersRes.data);
      setUsers(usersRes.data);
    }
    catch (err) {
      console.error(err);
      Alert.alert("Error fetching users", err.message);
    }
  }

  const [realmRolePermissions, setRealmRolePermissions] = React.useState([]);

  const onAuthLoaded = async () => {
    const rolesRes = await axios.get(`${API_BASE_URL}/admin/realm_role_permissions`);
    console.log("rolesRes:", rolesRes);

    setRealmRolePermissions(rolesRes.data);
  };

  React.useEffect(() => {
    if (auth) {
      onAuthLoaded();
    }
  }, [auth]);

  // if (!keycloak?.hasRealmRole("blx.admin")) {
  //   return (
  //     <div>Admin role is required for this view.</div>
  //   );
  // }

  return (
    <>
      <UserEditModal
        realmRolePermissions={realmRolePermissions}
        user={userEditUser}
        open={userEditOpen}
        onClose={() => {
          setUserEditOpen(false);
          setUserEditUser(null);
        }} />

        {/* <Button
              color="primary"
              style={{marginBottom: 20}}
              onClick={() => {
                setUserEditOpen(true)
              }}
            >Create User Account</Button> */}

        <div style={{ marginTop: 40, marginBottom: 40 }}>
          {keycloak?.hasRealmRole("blx.admin") ? (
              <TextField
                label="Search user"
                onChange={event => {
                  console.log("onChange:", event.target.value);
                  onUserSearchFieldChange(event.target.value);
                }}
                style={{ width: '100%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                autoFocus 
              />
          ) 
          : (
              <form 
              style={{display: 'flex'}}
                onSubmit={evt => {
                  evt.preventDefault()
                  onUserEmailSearchSubmit()
                }}
                >
                <TextField
                  label="Search user"
                  placeholder="E-Mail"
                  type="email"
                  value={userEmailSearchText}
                  onChange={evt => {
                    setUserEmailSearchText(evt.target.value)
                  }}
                  // style={{ width: '80%' }}
                  style={{ flexGrow: 1 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  autoFocus 
                />
                <Button 
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{margin: 7}}
                  >
                  search
                </Button>
              </form>
          )
          }
      </div>

      {users.length > 0 && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              {/* <TableCell>ID</TableCell> */}
              <TableCell>E-Mail</TableCell>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
            </TableHead>
            <TableBody>
              {users.map(user => (
                <TableRow>
                  {/* <TableCell>{user.id}</TableCell> */}
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.firstName} {user.lastName}</TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      onClick={() => {
                        setUserEditUser(user);
                        setUserEditOpen(true);
                      }}
                    >edit</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}


      <Button
        onClick={() => {
          setUserEditUser({})
          setUserEditOpen(true)
        }}
        color="primary"
        style={{marginTop: 15}}
        startIcon={<PersonAddIcon />}
        >New User</Button>

      {/* <pre>
              DEBUG: {'\n'}
              Allowed roles: {realmRolePermissions ? JSON.stringify(realmRolePermissions, null, 4) : 'null'}
            </pre> */}
    </>
  );
}
