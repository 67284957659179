import * as React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Icon, ListItem } from "react-native-elements";

export function NavListItem({ title, subtitle, titleStyle, screen, screenParams, iconName, topDivider, bottomDivider, ...props }) {
  const navigation = useNavigation();

  // const leftIcon = iconName ?
  //   ({ type: "font-awesome-5", color: 'grey', style: {width: 25, textAlign: 'center'}, name: iconName, solid: true })
  //   : undefined;
  return (

    <ListItem
      topDivider={topDivider}
      bottomDivider={bottomDivider}
      // {...props}
      onPress={() => {
        if (screen)
          navigation.navigate(screen, screenParams);
      }}
    >
      {iconName && (
        <Icon
          type="font-awesome-5"
          color="grey"
          style={{ width: 25, textAlign: 'center' }}
          name={iconName}
          solid />
      )}

      <ListItem.Content>
        <ListItem.Title style={titleStyle}>
          {title}
        </ListItem.Title>
        {subtitle && (
          <ListItem.Subtitle style={{ color: 'grey' }}>
            {subtitle}
          </ListItem.Subtitle>
        )}
      </ListItem.Content>

      <ListItem.Chevron />
    </ListItem>
  );
}
