
import { Alert as RNAlert, AlertStatic, AlertOptions, AlertButton, AlertType, Platform } from 'react-native';

export type AlertCallback = {
  alertCallback?: (title : string, message?: string, buttons?: AlertButton[], options?: AlertOptions) => void;
}

export type AlertWithCallback = AlertStatic & AlertCallback
/**
 * Alert implementation that supports web and configurable alert callback / handler methods
 */
export const Alert : AlertWithCallback = {
  alertCallback: undefined,

  alert: (title: string, message?: string, buttons?: AlertButton[], options?: AlertOptions) => {
    if(Alert.alertCallback) {
      Alert.alertCallback(title, message, buttons, options)
    }
    else if(Platform.OS === 'web') {
      alert(`${title}\n\n${message}`)
    }
    else {
      RNAlert.alert(title, message, buttons, options)
    }
  },

  prompt: (
      title: string,
      message?: string,
      callbackOrButtons?: ((text: string) => void) | AlertButton[],
      type?: AlertType,
      defaultValue?: string,
      keyboardType?: string
  ) => {
    RNAlert.prompt(title, message, callbackOrButtons, type, defaultValue, keyboardType)
  }
}


