import * as React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const AppSettingsContext = React.createContext({})

async function loadAppSettings() {
  const str = await AsyncStorage.getItem("appsettings")
  console.log("got str", str)
  if (str)
    return JSON.parse(str)
  else
    return {}
}

export function AppSettingsProvider({children}) {
  const [appSettings, setAppSettings] = React.useState({});
  const haveBeenLoadedInitially = React.useRef(false)

  React.useEffect(() => {
    console.log("AppSettingsProvider useEffect []")
    loadAppSettings()
    .then(s => {
      console.log("persisted settings: ", s)
      haveBeenLoadedInitially.current = true
      setAppSettings(s)
    })
    .catch(err => {
      console.error("error loading app settings:", err)
    })
  }, [])

  React.useEffect(() => {
    console.log("appSettings useEffect, ", appSettings)
    if(appSettings && haveBeenLoadedInitially.current) {
      AsyncStorage.setItem("appsettings", JSON.stringify(appSettings));
    }
  }, [appSettings])

  // const setAppSettings = async(obj) => {
  //   console.log("AppSettingsProvider setAppSettings")
  //   await AsyncStorage.setItem("appsettings", JSON.stringify(obj));
  //   setAppSettingsState(obj)
  // }

  return (
    <AppSettingsContext.Provider value={{appSettings, setAppSettings}}>
      {children}
    </AppSettingsContext.Provider>
  )
}

export function useAppSettings() {
  //const [appSettings, setAppSettings] = React.useState({});
  const {appSettings, setAppSettings} = React.useContext(AppSettingsContext)
  // const haveBeenLoaded = React.useRef(false)

  // React.useEffect(() => {
  //   console.log("appsettings changed, persisting ...", appSettings)
  //   if(!haveBeenLoaded.current) {
  //     console.error("app settings have not been loaded initially, skipping persist")
  //   }
  //   else if(appSettings) {
  //     AsyncStorage.setItem("appsettings", JSON.stringify(appSettings));
  //   }
  //   else {
  //     console.error("null appsettings, todo: delete")
  //   }
  // }, [ appSettings ]);

  // const load = async() => {
  //   const str = await AsyncStorage.getItem("appsettings")
  //   console.log("got str", str)
  //   if(str) {
  //     setAppSettings(JSON.parse(str));
  //     haveBeenLoaded.current = true
  //   }
  //   else
  //     console.log("no app settings persisted")
  // }

  // React.useEffect(() => {
  //   console.log("load app settings")
  //   load()
  // }, []);

  const mergeAppSettings = (update) => {
    setAppSettings(old => {
      return { ...old, ...update };
    });
  }

  const setAppSetting = (key, value) => {
    return mergeAppSettings({
      [key]: value
    })
  }
  // const getAppSetting = (key) => {
  //   return appSettings[key]
  // }

  return {
    appSettings,
    setAppSettings,
    mergeAppSettings,
    setAppSetting
  };
}

// todo: remove
export function useAppSettingsContext() {
  const ctx = React.useContext(AppSettingsContext)
  return ctx
}