import React, { useState } from 'react';
import axios from 'axios';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { BLXConfigEditView } from './BLXConfigEditView';
import { API_BASE_URL } from '../App';
import { BLXWebPseudoDevice } from './BLXWebPseudoDevice';
import { blxApplyConfiguration, blxLoadConfiguration } from '../screens/blx/BLXConfiguration';
import { Alert } from '../screens/ui/Alert';
import { useTranslation } from 'react-i18next';
import { filename } from '../FilesScreen';
import FileNameDialog from './FileNameDialog';

export function BLXConfigFileModal({ filePath, deviceVariant, open, onClose }) {
  const [configFileContent, setConfigFileContent] = React.useState(null);
  const [device, setDevice] = React.useState(null)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {t} = useTranslation();

  const [filenameDialogOpen, setFilenameDialogOpen] = useState(false)

  const loadFile = async() => {
    const getRes = await axios.get(`${API_BASE_URL}/fs/files/${filePath}`);
    console.log("fetchRes:", getRes);
    setConfigFileContent(getRes.data);
  };

  const loadVirtualDevice = async(config) => {
    let blxVersionText = config.deviceBLXVersionInfo?.versionText
    const newDev = new BLXWebPseudoDevice(blxVersionText);

    try {
      await blxApplyConfiguration(newDev, config).promise
    }
    catch(err) {
      console.error(err)
      Alert.alert(err.message)
    }

    setDevice(newDev)
  }

  React.useEffect(() => {

    // new file
    if(filePath?.endsWith('/')) {
      let blxVersionText = null;
      if(deviceVariant === "HK")
        blxVersionText = "Web BLX HK";
      else if(deviceVariant === "QU")
        blxVersionText = "Web BLX QU";
      else if(deviceVariant === "NA")
        blxVersionText = "Web BLX NA";
      else
        throw new Error(`Unsupported device variant: ${deviceVariant}`);

      setConfigFileContent({
        version: 1,
        deviceBLXVersionInfo: {
          versionText: blxVersionText,
        },
        data: {
          specialBits: [0, 0, 0]
        }
      });
    }
    else if(filePath) {
      loadFile();
    }
  }, [filePath]);

  React.useEffect(() => {
    if(configFileContent) {
      loadVirtualDevice(configFileContent)
    }
  }, [configFileContent]);

  const uploadConfiguration = async(configJson, path) => {
    const configStr = JSON.stringify(configJson)
    // const configBuf = Buffer.from(configStr, "utf-8")

    var blob = new Blob([configStr], { type: "application/json"});
    console.log("blob: ", blob)

    let formData = new FormData();
    formData.append('file', blob, {filename: "file"})

    const uploadRes = await axios.post(
      `${API_BASE_URL}/fs/files/${path}`, 
      formData, 
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

    console.log("save uploadRes", uploadRes);
  }

  const onSaveClick = async () => {
    if(filePath?.endsWith('/')) {
      setFilenameDialogOpen(true)
      return
    }

    try {
      const configJson = await blxLoadConfiguration(device).promise
      console.log("configJson:", configJson)

      await uploadConfiguration(configJson, filePath)
      onClose()
    }
    catch(err) {
      console.error(err)
      Alert.alert(err.message)
    }
  };

  const onFilenameDialogClose = async(filename) => {
    let targetFilePath = filePath + filename
    if(!targetFilePath.endsWith(".blxc")) {
      targetFilePath += ".blxc"
    }

    try {
      const configJson = await blxLoadConfiguration(device).promise
      console.log("configJson:", configJson)

      await uploadConfiguration(configJson, targetFilePath)
      onClose()
    }
    catch(err) {
      console.error(err)
      Alert.alert(err.message)
    }
    finally {
      setFilenameDialogOpen(false)
    }
  }

  const onDialogClosed = async() => {
    setConfigFileContent(null)
    onClose()
  }

  return (<>
    <FileNameDialog
      open={filenameDialogOpen}
      onClose={onFilenameDialogClose}
    />

    <Dialog
      open={open}
      onClose={() => onDialogClosed()}
      // fullWidth={true}
      disableBackdropClick
      // style={{width: 600}}
      maxWidth={false}
      fullScreen={fullScreen}
    >
      <DialogTitle>
        {t('BLX Configuration')} {(!filePath || filePath?.endsWith('/')) ? '' : ` '${filename(filePath)}'`}
      </DialogTitle>

      <DialogContent style={{ padding: 0}} dividers>
        {device && (
          <BLXConfigEditView 
            device={device}
            configFileContent={configFileContent}
            />
        )}

        {!device && (
          <Grid alignItems="center" justify="center" container style={{minWidth: 200, minHeight: 200}} >
            <CircularProgress />
          </Grid>
        )}

      </DialogContent>

      <DialogActions>
        <Button onClick={onDialogClosed} color="grey">
          Cancel
        </Button>
        <Button onClick={onSaveClick} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  </>);
}
