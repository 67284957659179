import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import Keycloak from 'keycloak-js';
import { AppBar, Button, CircularProgress, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Toolbar, useMediaQuery, useTheme } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import TuneIcon from '@material-ui/icons/Tune';
import BluetoothIcon from '@material-ui/icons/Bluetooth';

import { Route, Switch } from 'react-router';
import { BrowserRouter, Link, NavLink } from 'react-router-dom';
import { ExitToApp } from '@material-ui/icons';

import RNVectorIconsMaterial from "react-native-vector-icons/Fonts/MaterialIcons.ttf";
import RNVectorIconsFA5Regular from "react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf";
import RNVectorIconsFA5Solid from "react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf";

import { TestScreen } from './TestScreen';

import { AdminView } from './admin/AdminView';
import { FilesScreen } from './FilesScreen';
import { LocIdDebugScreen } from './LocIdDebugScreen';

export const AuthContext = React.createContext()

export const API_BASE_URL = (document.location.hostname === 'localhost')
  ? "https://blx.rtb-dev.de/api/v1"
  : "/api/v1";

const IAM_URL = "https://iam.rtb-bl.de/auth";

export const keycloak = Keycloak({
  url: IAM_URL,
  realm: 'rtb',
  clientId: "rtb-blx",
})

// auto-inject / refresh auth token on api calls
axios.interceptors.request.use(async(config) => {
  console.log("axios interceptor: ", config)
  if(!config?.headers?.Authorization) {
    if(!keycloak) {
      console.error("keycloak has not been loaded!")
    }
    await keycloak.updateToken(30)
    config.headers['Authorization'] = `Bearer ${keycloak.token}`;
  }

  return config;
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://rtb-bl.de/">
        RTB GmbH
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export function AudioFilePreviewModal({file, open, onClose}) {
  const [loading, setLoading] = React.useState(false)
  const audioRef = React.useRef(null)

  const loadFile = async() => {
    setLoading(true)
    try {
      const res = await axios.get(`${API_BASE_URL}/fs/files/${file.object_name}`, {
          responseType: 'blob'
        }
      )
      console.log("getRes:", res)

      const blobUrl = window.URL.createObjectURL(new Blob([res.data]));
      console.log("blobUrl", blobUrl)

      audioRef.current.src = blobUrl
      audioRef.current.play()
    }
    finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if(file) {
      loadFile()
    }
  }, [file])

  return (<>
    <Dialog
      open={open}
      onClose={() => onClose()}
      >
      <DialogTitle>
        Audio file '{file?.name}'
      </DialogTitle>

      <DialogContent dividers>
        <Box display="flex" justifyContent="center">
          {loading && (
            <CircularProgress />
          )}

          <audio
            ref={audioRef}
            controls={!loading}
            type="audio/mp3"
            src=""
            />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
    </>)
}

function HomeView() {
  return (
    <Box my={4}>
      <Typography variant="h4" component="h1" gutterBottom>
        BLX Online (alpha)
      </Typography>
      {/* <ProTip /> */}
    </Box>
  )
}

export default function App() {

  const [auth, setAuth] = React.useState(null)
  const [drawerOpen, setDrawerOpen] = React.useState(true);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // close drawer when switching from large to small screen
  React.useEffect(() => {
    if(isSmallScreen)
      setDrawerOpen(false)
  }, [isSmallScreen])

  const onLoad = async() => {
    console.log("onLoad")

    console.log("keycloak:", keycloak)
    window.keycloak = keycloak

    const initRes = await keycloak.init({ onLoad: 'login-required'})
    console.log("initRes:", initRes)
    if(initRes === true) {
      window.oidcAccessToken = keycloak.token;

      setAuth(old => {
        return {
          ...old,
          oidcAccessToken: keycloak.token,
          loggedIn: true,
          idTokenParsed: keycloak.idTokenParsed,
          tokenParsed: keycloak.tokenParsed,
       }}
      )

      setInterval(() => {
        console.log("update token...")
        keycloak.updateToken(60)
      }, 60000)
    }
  }

  React.useEffect(() => {
    // adminApiTest()
    onLoad()
  }, [])

  const signOut = async() => {
    window.keycloak.logout()
  }

  const onDrawerButtonClicked = async() => {
    setDrawerOpen(old => !old)
  }

  // const location = useLocation()
  // const activeRoute = (routeName: any) => {
  //   return props.location.pathname === routeName ? true : false;
  // }

  const drawerWidth = 250

  // useEffect(() => {
  //   console.log("INJECTING FONTS")

  //   const iconFontStyles = `@font-face {
  //     src: url(${FA5IconSolid});
  //     font-family: FontAwesome;
  //   }`;
  //   const style = document.createElement('style');
  //   style.type = 'text/css';
  //   if (style.styleSheet) {
  //     style.styleSheet.cssText = iconFontStyles;
  //   } else {
  //     style.appendChild(document.createTextNode(iconFontStyles));
  //   }

  //   // Inject stylesheet
  //   document.head.appendChild(style);

  //   // Alert.alert(`url: ${FA5IconSolid}`)

  // }, [])

  return (<>
    {/* load icons for react-native-elements */}
    <style type="text/css">{`
        @font-face {
          font-family: 'MaterialIcons';
          src: url(${RNVectorIconsMaterial}) format('truetype');
        }
        @font-face {
          font-family: 'FontAwesome';
          src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'FontAwesome5_Solid';
          src: url(${RNVectorIconsFA5Solid}) format('truetype');
        }
        @font-face {
          font-family: 'FontAwesome5_Regular';
          src: url(${RNVectorIconsFA5Regular}) format('truetype');
        }
      `}</style>

    <AuthContext.Provider value={{auth, setAuth}}>
    <BrowserRouter>

    <div style={{display: 'flex'}}>
      <CssBaseline />

      <AppBar position="fixed"
        style={{zIndex: 5}}
        // elevation={1}
        >
        <Toolbar
          // variant="dense"
          style={{backgroundColor: '#e6e5e5'}}
          >

          {isSmallScreen && (
            <IconButton
              color="white"
              aria-label="open drawer"
              onClick={onDrawerButtonClicked}
              edge="start"
              // className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          )}


          <Typography component="h1" variant="h6" color="inherit" noWrap
            style={{color: '#e36600'}}
            >
            BLX
          </Typography>
          <Typography component="h1" variant="h6" color="inherit" noWrap
            style={{color: '#5e6a6f', fontWeight: 400, paddingLeft: 0, flexGrow: 1}}
            >
            .online
          </Typography>

          <Typography style={{color: "#5e6a6f"}} noWrap>
            {auth?.idTokenParsed?.name}
          </Typography>

          <IconButton
            onClick={signOut}
            >
            <ExitToApp />
          </IconButton>

        </Toolbar>
      </AppBar>

    <Drawer
      variant={isSmallScreen ? "temporary" : "permanent"}
      open={drawerOpen}
      style={{width: drawerWidth, zIndex: 1, flexShrink: 0}}
      PaperProps={{style: {width: drawerWidth}}}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      onEscapeKeyDown={() => setDrawerOpen(false)}
      onBackdropClick={() => setDrawerOpen(false)}
    >

      <Toolbar />

      <List onClick={() => {setDrawerOpen(false)}}>
        <ListItem button component={NavLink} to="/" activeClassName="Mui-selected" exact>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>

        {/* <ListItem button component={NavLink} to="/files" activeClassName="Mui-selected">
          <ListItemIcon>
            <FilesIcon />
          </ListItemIcon>
          <ListItemText primary="Library" />
        </ListItem> */}

        {/* <Divider /> */}

        <List
          subheader={
            <ListSubheader component="div">
              Library
            </ListSubheader>
          }
        >

        <ListItem button component={NavLink} to="/files/configs/" activeClassName="Mui-selected">
          <ListItemIcon>
            <TuneIcon />
          </ListItemIcon>
          <ListItemText primary="Configurations" />
        </ListItem>
        <ListItem button component={NavLink} to="/files/sounds/" activeClassName="Mui-selected">
          <ListItemIcon>
            <AudiotrackIcon />
          </ListItemIcon>
          <ListItemText primary="Sounds" />
        </ListItem>
        <ListItem button component={NavLink} to="/files/doc/" activeClassName="Mui-selected">
          <ListItemIcon>
            <AudiotrackIcon />
          </ListItemIcon>
          <ListItemText primary="Documents" />
        </ListItem>

        {(auth?.tokenParsed?.realm_access?.roles ?? []).includes('rtb.staff') && (
          <ListItem button component={NavLink} to="/files/firmwares/" activeClassName="Mui-selected">
            <ListItemIcon>
              <BluetoothIcon />
            </ListItemIcon>
            <ListItemText primary="Firmwares" />
          </ListItem>
        )}

        </List>

        <List
          subheader={
            <ListSubheader component="div">
              Admin
            </ListSubheader>
          }
        >
          <ListItem button component={NavLink} to="/admin" activeClassName="Mui-selected">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        </List>

        {/* <ListItem button component={Link} to="/test">
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Test" />
        </ListItem> */}

        {(auth?.tokenParsed?.realm_access?.roles ?? []).includes('blx.locid.debug') && (<>
          <List
            subheader={
              <ListSubheader component="div">
                Internal
              </ListSubheader>
            }
          >
            <ListItem button component={Link} to="/locid_debug">
              <ListItemIcon>
                <PhoneIphoneIcon />
              </ListItemIcon>
              <ListItemText primary="LOC.id debug" />
            </ListItem>
          </List>
        </>)}

      </List>

    </Drawer>

    <main style={{flexGrow: 1, height: '100vh', overflow: 'auto',
      // marginLeft: drawerOpen ? 0 : -drawerWidth
      }}>
      <Toolbar />
      <Container maxWidth="lg">
        <Switch>
          <Route exact path="/" component={HomeView} />
          <Route path="/admin" component={AdminView} />
          <Route path="/files" component={FilesScreen} />
          <Route path="/test" component={TestScreen} />
          <Route path="/configs" component={FilesScreen} />
          <Route path="/locid_debug" component={LocIdDebugScreen} />
        </Switch>

        {/* <Copyright /> */}
        {/* <Box style={{height: 100}}>lalalaa</Box> */}
      </Container>
    </main>

    {/* <footer>
      lalala
      <Copyright />
    </footer> */}

    </div>

    </BrowserRouter>
    </AuthContext.Provider>
  </>);
}
