import * as React from 'react';
import { Text, ListItem } from 'react-native-elements';
import Colors, { appStyles } from '../../constants/Colors';
import { ActivityIndicator, Platform, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { getNumberFormatSettings } from 'react-native-localize';
import { Alert } from './Alert';

export function SectionHeader(props) {

  let st = {}
  if(Platform.OS === 'android') {
    st.fontWeight = 'bold'
    st.marginTop = 25
  }
  else if(Platform.OS === 'web') {
    st.fontWeight = 'bold'
    st.marginTop = 25
    st.marginBottom = 10
    st.marginLeft = 10
  }

  return (
    <Text 
      style={{...appStyles.sectionHeader, ...st, ...props.style}}
      >{props.children}</Text>
    )
}

export function Spacer({height}) {
  return (
    <View style={{height: height ?? 20}} />
  )
}

export function NumericInputItem({title, 
  value, 
  onValueChange, 
  onValueSubmit,
  unit,
  placeholder,
  valueType,
  minValue,
  maxValue,
  nullValue, // todo
  inputValueTransform,
  input,
  ...props}) {

  valueType = valueType ?? 'float'

  const [displayValue, setDisplayValue] = React.useState('')
  const {t} = useTranslation()
  const inputRef = React.useRef(null)

  const strToVal = (str) => {
    if(str?.length === 0)
      return null

    if(valueType === 'number' || valueType === 'integer')
      return (str ? parseInt(str) : null)
    else {
      const numberFormat = getNumberFormatSettings()
      str = str.replace(numberFormat.groupingSeparator, "")
      str = str.replace(numberFormat.decimalSeparator, ".")
      return (str ? parseFloat(str) : null)
    }
  }

  const valueStringRepr = (v) => {
    if(!v)
      return ""
    if(nullValue && v === nullValue)
      return ""

    if(valueType === 'number' || valueType === 'integer')
      return v.toString()
    else
      return v.toLocaleString()
  }

  console.log("numericInput render value:", value, "displayValue:", displayValue)

  React.useEffect(() => {
    console.log("numericInput value changed, value:", value, "nullValue", nullValue)

    if(nullValue !== undefined && nullValue !== null && value === nullValue) {
      console.log("setting nullValue")
      setDisplayValue("")
    }
    else {
      const newDisplayValue = valueStringRepr(value)
      console.log("setting displayValue:", newDisplayValue)
      setDisplayValue(newDisplayValue)
    }
  }, [value])

  React.useEffect(() => {
    console.log("numericInput created!!!")
  }, [])

  const onEndEditing = (evt) => {
    console.log("NumericInput onEndEditing, text:", evt.nativeEvent.text)
    // todo: check for min/max
    const strValue = evt.nativeEvent.text;

    if((strValue === null || strValue?.length === 0) && nullValue !== undefined) {
      console.log("setting to nullValue:", nullValue)
      setDisplayValue(valueStringRepr(nullValue))
      if(onValueSubmit)
        onValueSubmit(nullValue)
      return
    }

    let newVal = strToVal(evt.nativeEvent.text)
    console.log("newVal encoded:", newVal)

    if(inputValueTransform) {
      newVal = inputValueTransform(newVal)
    }

    if( (minValue != undefined && newVal != undefined && newVal < minValue) 
      || (maxValue != undefined && newVal != undefined && newVal > maxValue) ) {
      
      const validRangeStr = `[${minValue?.toLocaleString() ?? '*'} ... ${maxValue?.toLocaleString() ?? '*'}]`
      setDisplayValue(valueStringRepr(value))

      Alert.alert(
        t("Invalid value"), 
        t("Entered value is outside of the allowed range.") + " " + validRangeStr)
      return
    }

    setDisplayValue(valueStringRepr(newVal))

    if(onValueSubmit) {
      onValueSubmit(newVal)
      // setTimeout(() => {
      //   onValueSubmit(newVal)
      // }, 1000)
    }
  }

  const onChangeText = (text) => {
    console.log("NumericInput onChangeText:", text)
    setDisplayValue(text)

    if(onValueChange) {
      console.error("use onValueSubmit!!!")
      onValueChange(strToVal(text))
    }
  }

  return (
    <ListItem 
      {...props}
      disabledStyle={{opacity: 0.5}}
      containerStyle={
        (Platform.OS === 'ios') ?
          {paddingRight: 16, paddingTop: 10, paddingBottom: 10, minHeight: 50 } :
          {paddingRight: 16, paddingTop: 4, paddingBottom: 4, minHeight: 50 }
      }
      onPress={() => { 
        if(inputRef.current)
          inputRef.current.focus()
      }}
    >
      <ListItem.Content style={{backgroundColor: 'transparent'}}>
        <ListItem.Title>{title}</ListItem.Title>
      </ListItem.Content>
      <ListItem.Input
        // ref={inputRef}
        // ref={component => {
        //   if(component)
        //     console.log("NumericInput ref called, e:", component, component.children)
        //   // inputRef.current = component
        // }}
        // HACK: ios/android doesn't support forwardRef, we need to set ref on onLayout
        onLayout={e => { 
          console.log("NumericInput onLayout2, e:", e)
          inputRef.current = e.currentTarget ?? e.nativeEvent?.target
          console.log("target:", inputRef.current)
        }}
        value={displayValue?.toString() ?? 'noval'} 
        onChangeText={t => onChangeText(t)}
        onEndEditing={evt => onEndEditing(evt)}
        onSubmitEditing={evt => {
          console.log("NumericInput onSubmitEditing:", evt)
        }}
        onBlur={evt => {
          console.log("NumericInput onBlur:", evt)
          // web doesn't call onEndEditing
          if(Platform.OS === 'web')
            onEndEditing(evt)
        }}
        // style={{height: 100, textAlign: "right"}}
        style={{fontSize: (Platform.OS === 'ios') ? 17 : 16, margin: 0, padding: 0, minHeight: null}}
        containerStyle={{backgroundColor: 'transparent', padding: 0, margin: 0}}
        inputStyle={{paddingHorizontal: 0}}
        selectTextOnFocus={true}
        keyboardType="numeric"
        returnKeyType='done'
        enablesReturnKeyAutomatically={true}
        contextMenuHidden={true}
        placeholder={placeholder}
        placeholderTextColor='gray'
        // rightElement={unit ? <Text>{unit}</Text> : undefined}
        // style: Platform.OS === 'android' ? {fontSize: 16, minHeight: null} : {},
        // {...input}
      />
      <Text style={{
        // flex: 1,
        // alignSelf: 'center',
        // flexShrink: 1,
        // height: '100%',
        // textAlignVertical: 'bottom',
        // textAlign: 'right',
        fontSize: (Platform.OS === 'android') ? 16 : 17, 
        color: 'grey',
        marginLeft: 0,
        paddingTop: 2,
        }}>
        {unit ?? ""}
      </Text>
    </ListItem>
    // <ListItem
    //   title={title}
    //   input={{
    //     ...input,
    //     //value: value?.toString(),
    //     value: displayValue?.toString() ?? null,
    //     // onChangeText: t => onValueChange && onValueChange(strToVal(t)),
    //     onChangeText: t => onChangeText(t),
    //     onEndEditing: evt => onEndEditing(evt),
    //     selectTextOnFocus: true,
    //     keyboardType: "numeric",
    //     returnKeyType: 'done',
    //     enablesReturnKeyAutomatically: true,
    //     contextMenuHidden: true,
    //     placeholder: placeholder,
    //     placeholderTextColor: 'gray',
    //     // style: {fontSize: 9},
    //     inputStyle: {fontSize: 17}
    //   }}
    //   // rightTitleStyle={{color: 'red'}}
    //   rightElement={unit ? <Text>{unit}</Text> : undefined}
    //   disabledStyle={{opacity: 0.5}}
    //   {...props}
    // /
  )
}

export function SwitchListItem({title, subtitle, value, onValueChange, disabled, ...props}) {
  let trackColorOpts = Platform.OS === 'ios' 
    ? {trackColor: {true: "orange", false: "#fbfbfb"}} 
    // : undefined
    : {
      trackColor: {true: '#fff5e6', false: "lightgrey"}, 
      thumbColor: value ? 'orange' : '#A0A0A0', 
    }
  // let thumbColorOpts = Platform.OS === 'android' ? {thumbColor: 'orange'} : undefined

  return (
    <ListItem
      title={title}
      titleStyle={{color: 'black'}}
      subtitle={subtitle}
      subtitleStyle={{
        color: 'gray', 
        marginTop: (Platform.OS === 'android' ? 5 : undefined)
      }}
      containerStyle={
        (Platform.OS === 'android') ?
          { minHeight: 50 } :
          undefined
      }
      switch={{
        value: value,
        onValueChange: onValueChange,
        disabled: disabled,
        ...trackColorOpts,
        // ...thumbColorOpts
        // trackColor: {
        //   true: Platform.OS === 'android' ? undefined : 'orange',
        //   false: Platform.OS === 'android' ? '#d3d3d3' : '#fbfbfb' },
        // thumbColor: [Platform.OS=='ios'?'#FFFFFF':(item.status ?'#7ab8e1':'#ffffff')],
        // ios_backgroundColor: "#fbfbfb",
      }}
      bottomDivider
      disabled={disabled}
      disabledStyle={{opacity: 0.5}}
      {...props}
      />
  )
}

export const DescriptionListItem = ({text}) => {
  return (
    <Text
      style={{marginLeft: 15, marginTop: 8, marginBottom: 20, marginRight: 15, color: Colors.lightText}}
        >{text}</Text>
  )
}

export function useStatusBarActivity() {
  const navigation = useNavigation()
  // const [show, setShow] = React.useState(false)
  const [activity, setActivity] = React.useState(false)

  React.useEffect(() => {
    if(activity) {
      console.log("useStatusBar NAV:", navigation)
      console.log("useStatusBar NAV state:", navigation.dangerouslyGetState())
      console.log("useStatusBar NAV headerRight:", navigation.headerRight)
      console.log("useStatusBar NAV headerComponent:", navigation.headerComponent)
      navigation.setOptions({
        headerRight: props => (<ActivityIndicator color={Colors.tintColor} style={{marginRight: 20}} />)
      })
    }
    else {
      navigation.setOptions({
        headerRight: undefined
      })
    }
  }, [activity])

  // const setActivity = (val) => {
  //   if(val) {
  //     navigation.setOptions({
  //       headerRight: props => (<ActivityIndicator color={Colors.tintColor} style={{marginRight: 20}} />)
  //     })
  //   }
  //   else {
  //     navigation.setOptions({
  //       headerRight: undefined
  //     })
  //   }
  // }

  return {activity, setActivity}
}