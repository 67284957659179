import * as React from 'react';
import { ListItem } from 'react-native-elements';

export function SelectItem({ items, value, onValueChange, disabled, title, topDivider, bottomDivider, ...props }) {
  return (
    <ListItem
      topDivider={topDivider}
      bottomDivider={bottomDivider}
      >
      <ListItem.Content>
        <ListItem.Title>
          {title}
        </ListItem.Title>
      </ListItem.Content>

      <select
        onChange={(evt) => {
          const val = items[evt.target.selectedIndex].value;
          console.log("onChange ", val)
          onValueChange(val)
        }}
        style={{paddingTop: 3, paddingBottom: 3, paddingLeft: 5, paddingRight: 5, borderColor: 'grey'}}
      >
        {items.map((item, _) => {
          return (
            <option
              value={item.value}
              selected={item.value === value}
            >{item.label}</option>
          )
        })}

      </select>
    </ListItem>
  )
}