import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from "../ui/Alert";
import { Text } from "react-native-elements";
import { DeviceContext } from '../common/DeviceContext';
import { DescriptionListItem, NumericInputItem, SectionHeader, Spacer } from '../ui/RTBUI';
import { BLXSpecialBitSwitchListItem } from './common/BLXSpecialBitSwitchListItem';
import { BLXDebugSpecialBits, DebugView } from './common/DebugView';
import { useBlxSpecialBits } from './protocol/useBlxSpecialBits';
import { BLXScreenContainer } from './BLXScreenContainer';


export function BLXApsSettingsScreen() {
  const { device } = React.useContext(DeviceContext);
  const { specialBits, specialBitsRead, specialBitsWrite } = useBlxSpecialBits(device);
  const { t } = useTranslation();
  const [timerMagnet, setTimerMagnet] = React.useState(null);

  const load = async () => {
    await specialBitsRead();

    let timerMagnetRes = await device.jsonRpc("blx.getTimerMagnet");
    setTimerMagnet(timerMagnetRes.value);
  };

  const sendTimerMagnet = async (val) => {
    try {
      await device.jsonRpc("blx.setTimerMagnet", { value: val });
      await load();
    }
    catch (err) {
      Alert.alert(t("Device error"), err.message);
    }
  };

  React.useEffect(() => {
    if (device)
      load();
  }, [device]);

  return (
    <BLXScreenContainer onReload={load}>
      <SectionHeader>
        {t('Feedback')}
      </SectionHeader>

      <BLXSpecialBitSwitchListItem
        title={t("Enable voice announcement")}
        subtitle="A[2]"
        register={0}
        bitIndex={0x2}
        specialBits={specialBits}
        specialBitsWrite={specialBitsWrite}
        topDivider
        bottomDivider />
      <BLXSpecialBitSwitchListItem
        title={t("Beep on button press")}
        subtitle="A[E]"
        register={0}
        bitIndex={0xE}
        specialBits={specialBits}
        specialBitsWrite={specialBitsWrite}
        bottomDivider />
      {/* <ListItem
              title="Hold time"
              subtitle="B[0-1]"
              rightElement={(<Text>0 | 2 | 3 | 4 s</Text>)}
              chevron
              bottomDivider
            /> */}

      <SectionHeader>
        {t("Vibtrotactile feedback")}
      </SectionHeader>

      {/* <View style={{height: 40}}></View> */}
      <BLXSpecialBitSwitchListItem
        title={t("Vibrotactile signal always enabled")}
        subtitle="B[5]"
        register={1}
        bitIndex={0x5}
        specialBits={specialBits}
        specialBitsWrite={specialBitsWrite}
        topDivider
        bottomDivider />
      <DescriptionListItem
        text={t("Enables vibrotactile signal even if walk signal is muted")} />

      <Spacer />

      <NumericInputItem
        title={t("Maximum duration")}
        value={timerMagnet}
        minValue={5}
        maxValue={15}
        nullValue={16}
        placeholder={t('disabled')}
        valueType="number"
        onValueSubmit={sendTimerMagnet}
        unit="s"
        topDivider
        bottomDivider />

      {/* <Spacer />
            <BLXSpecialBitSwitchListItem
              title={t("Automatic APS call when LOC.id user is nearby")}
              subtitle="A[B]"
              register={0}
              bitIndex={0xB}
              specialBits={specialBits}
              specialBitsWrite={specialBitsWrite}
              topDivider
              bottomDivider
            /> */}

      <DebugView>
        <Spacer />
        <Text>DEBUG:</Text>
        <BLXDebugSpecialBits specialBits={specialBits} />
      </DebugView>

    </BLXScreenContainer>
  );
}
