import * as React from 'react';
import { View, Text } from "react-native";
import { useAppSettings } from '../../common/useAppSettings';

export function DebugView({ children }) {
  const {appSettings} = useAppSettings()

  if(appSettings?.developerMode) {
    return (
      <View style={{ opacity: 0.7, margin: 10 }}>
        {children}
      </View>
    );
  }
  else {
    return (<></>)
  }
}

export function BLXDebugSpecialBits({specialBits}) {
  return (
    <>
      <Text>specialBits: _: FEDCBA9876543210</Text>
      <Text>specialBits: A: {specialBits[0]?.toString(2).padStart(16, '0')}</Text>
      <Text>specialBits: B: {specialBits[1]?.toString(2).padStart(16, '0')}</Text>
      <Text>specialBits: C: {specialBits[2]?.toString(2).padStart(16, '0')}</Text>
    </>
  )
}