import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from "react-native";
import { ListItem, Text } from "react-native-elements";
import { UserContext } from '../account/User';
import { DeviceContext } from '../common/DeviceContext';
import { SectionHeader, Spacer } from '../ui/RTBUI';
import { BLXSpecialBitMultiSelectListItem } from './common/BLXSpecialBitMultiSelectListItem';
import { BLXSpecialBitSwitchListItem } from './common/BLXSpecialBitSwitchListItem';
import { BLXDebugSpecialBits, DebugView } from './common/DebugView';
import { useBlxSpecialBits } from './protocol/useBlxSpecialBits';
import { BLXScreenContainer } from './BLXScreenContainer';
import { isset } from '../jsutils';
import { useNavigation } from '@react-navigation/core';
import { SelectItem } from '../SelectItem';

export function BLXPilotScreen() {
  const { device } = React.useContext(DeviceContext);
  const { user } = React.useContext(UserContext);
  const { t } = useTranslation();
  const { specialBits, specialBitsRead, specialBitsWrite } = useBlxSpecialBits(device);
  const navigation = useNavigation()

  const onLoad = async () => {
    await specialBitsRead();
  };

  React.useEffect(() => {
    onLoad();
    return () => { };
  }, []);

  if (!device) {
    return <Text>no device</Text>;
  }

  return (
    <BLXScreenContainer onReload={onLoad}>
      <SectionHeader>
        {t('Volume profiles / locator tone')}
      </SectionHeader>

      <ListItem
        title={device?.isHongkong() ? t("Day") : t("PED")}
        subtitle={device?.isHongkong() ? t("Mode 1") : t("Default profile")}
        subtitleStyle={{ color: 'gray' }}
        onPress={() => navigation.navigate("BLXToneProfile", { mode: "day", tone: "PT", title: t("PED volume profile") })}
        leftIcon={{
          type: "font-awesome-5", color: 'gray', style: { width: 30, textAlign: 'center' },
          name: (device?.isHongkong() ? "sun" : "traffic-light"),
          solid: true
        }}
        chevron
        bottomDivider
        topDivider />

      <ListItem
        title={device?.isHongkong() ? "Night" : "APS / LOC.id"}
        subtitle={device?.isHongkong() ? t("Mode 2") : t("Nearby assistive pedestrian")}
        subtitleStyle={{ color: 'gray' }}
        onPress={() => navigation.navigate("BLXToneProfile", { mode: "night", tone: "PT", title: t("APS volume profile") })}
        leftIcon={{
          type: "font-awesome-5", color: 'gray', style: { width: 30, textAlign: 'center' },
          name: 'blind',
          name: (device?.isHongkong() ? "moon" : "blind"),
          solid: true
        }}
        chevron
        bottomDivider />

      <Spacer />

      {(device?.isNorthAmerica() || device?.isQuebec()) && <>
        <BLXSpecialBitMultiSelectListItem
          title={t("Volume adjustment")}
          subtitle="A[C-D]"
          register={0}
          bitMask={0b0011000000000000}
          specialBits={specialBits}
          specialBitsWrite={specialBitsWrite}
          // value={0}
          items={[
            { label: t('0 dB'), value: 0b0000000000000000 },
            { label: t('+3 dB'), value: 0b0010000000000000 },
            { label: t('+6 dB'), value: 0b0011000000000000 },
            { label: t('+6 dB (only on APS)'), value: 0b0001000000000000 }, //2
          ]}
          topDivider
          bottomDivider 
          />
      </>}

      {device?.isHongkong() && (<>
        <Spacer />
        <SelectItem
          title={t('Tone variant')}
          items={[
            { label: t('0'), value: 0 },
            { label: t('1'), value: 1 },
            { label: t('2'), value: 2 },
          ]}
          onValueChange={(val) => {}}
          topDivider
          bottomDivider
        />

        <Text style={{color: '#f08400', margin: 10}}>
          (Switch tone variant / 52: CMD_FADER_LS:{'\n'}Wär cool, wenn wir die für PT/FT einzeln konfigurieren können, dann kann ich's hier rein machen 
          und es wär konsistenter mit den NA-Varianten.)
        </Text>

        <SectionHeader>
          {t("Speakers")}
        </SectionHeader>

        <SelectItem
          title={t('Front speakers volume adjustment')}
          items={[
            { label: t('0 dB'), value: 0 },
            { label: t('1 dB'), value: 1 },
            { label: t('2 dB'), value: 2 },
            { label: t('...'), value: 3 },
            { label: t('15 dB'), value: 15 },
          ]}
          onValueChange={(val) => {}}
          topDivider
        />
        <SelectItem
          title={t('Side speakers volume adjustment')}
          items={[
            { label: t('0 dB'), value: 0 },
            { label: t('1 dB'), value: 1 },
            { label: t('2 dB'), value: 2 },
            { label: t('...'), value: 3 },
            { label: t('15 dB'), value: 15 },
          ]}
          onValueChange={(val) => {}}
          topDivider
          bottomDivider
        />
        <Text style={{color: '#f08400', margin: 10}}>
          52: CMD_FADER_LS

          {'\n'}Unschönheit: CMD_FADER_LS steuert die Dämpfungen für PT und SPM zusammen. Wär super wenn wir das in PT, FT und SPM unterteilen könnten. 
          
          
          {'\n'}{'\n'}Nice to have: An/auschalten der jeweiligen speakers (-> Konsistenz Walk/Voice messages menüs). 
          Das hätte zusätzlich den Vorteil, dass wir uns "51: CMD_SIDE_LS" komplett sparen können.
         )
          )
        </Text>


        <Spacer />

        <BLXSpecialBitSwitchListItem
          title={t("PiT speaker output")}
          subtitle="B[F]"
          specialBits={specialBits}
          specialBitsWrite={specialBitsWrite}
          register={1}
          bitIndex={0x0F}
          topDivider 
          />

        <Text style={{color: '#f08400', margin: 10}}>
          Nice to have: Neues Kommando (ähnlich 56: CMD_SPM_OUTPUT), z.B. CMD_PT_OUTPUT mit bitmasks für front/seiten/pit-lautsprecher.
        </Text>
      </>)}

      {(device?.isNorthAmerica() || device?.isQuebec()) && <>
        <SectionHeader>
          {t("Speakers")}
        </SectionHeader>

        <BLXSpecialBitSwitchListItem
          title={t('BLX Speaker output')}
          subtitle="!B[C]"
          specialBits={specialBits}
          specialBitsWrite={specialBitsWrite}
          register={1}
          bitIndex={0x0C}
          invert={true}
          topDivider />

        <BLXSpecialBitSwitchListItem
          title={t("Sharp tone pitch")}
          subtitle="A[5]"
          specialBits={specialBits}
          specialBitsWrite={specialBitsWrite}
          register={0}
          bitIndex={0x05}
          invert={true}
          disabled={isset(specialBits[1], 0xC)}
          bottomDivider />

        <Spacer />

        <BLXSpecialBitSwitchListItem
          title={t("PiT speaker output")}
          subtitle="B[F]"
          specialBits={specialBits}
          specialBitsWrite={specialBitsWrite}
          register={1}
          bitIndex={0x0F}
          topDivider />

        <BLXSpecialBitSwitchListItem
          // title="Playback locator tone at push button speaker"
          title={t("Sharp tone pitch")}
          subtitle="A[6]"
          specialBits={specialBits}
          specialBitsWrite={specialBitsWrite}
          register={0}
          bitIndex={0x06}
          disabled={!isset(specialBits[1], 0x0F)}
          bottomDivider />
      </>}

      <DebugView>
        <Spacer />
        <Text>DEBUG:</Text>
        <BLXDebugSpecialBits specialBits={specialBits} />
      </DebugView>

      <View style={{ height: 30 }} />
    </BLXScreenContainer>
  );
}
