import * as React from 'react';
import { Alert } from "react-native";

export function blxLoadConfiguration(device) {
  let cancelled = false
  const cancel = () => {
    cancelled = true
  }

  const promise = new Promise((resolve, reject) => {
    (async() => {
      try {
        let config = {data: {}};
        const versionInfo = await device.jsonRpc("blx.getVersion");
        let version = versionInfo.versionText;

        config.version = 1
        config.readDate = (new Date()).getTime()
        config.deviceBLEVersion = device.bleVersionText
        config.deviceBLXVersionInfo = versionInfo
        config.data.specialBits = await device.jsonRpc("blx.getSpecialBits")
        config.data.audioProfileDayPT = await device.jsonRpc("blx.getAudioProfile", {mode: 'day', tone: 'PT'})
        config.data.audioProfileDayFT = await device.jsonRpc("blx.getAudioProfile", {mode: 'day', tone: 'FT'})
        config.data.audioProfileNightPT = await device.jsonRpc("blx.getAudioProfile", {mode: 'night', tone: 'PT'})
        config.data.audioProfileNightFT = await device.jsonRpc("blx.getAudioProfile", {mode: 'night', tone: 'FT'})
        config.data.timerAudio = await device.jsonRpc("blx.getTimerAudio")
        config.data.timerMagnet = await device.jsonRpc("blx.getTimerMagnet")
        console.log("config:", config)

        resolve(config)
      }
      catch(error) {
        reject(error)
      }
    })()
  })

  return { promise, cancel }
}

export function blxApplyConfiguration(device, config) {
  let cancelled = false
  const cancel = () => {
    cancelled = true
  }

  const promise = new Promise((resolve, reject) => {
    (async() => {
      try {
        let versionInfo = await device.jsonRpc("blx.getVersion");
        let version = versionInfo.versionText;
        console.log("config:", config)

        if(config.version !== 1) {
          throw new Error("Invalid config version (please update app)")
        }

        let errors = {}

        const data = config.data;
        if(!data) {
          throw new Error("Invalid config format")
        }

        if(data.specialBits) {
          try {
            await device.jsonRpc("blx.setSpecialBits", data.specialBits, 60)
          }
          catch(err) {
            console.error("special bits err:", err)
            Alert.alert("Device error", err.message)
            errors['specialBits'] = err
          }
        }

        for(let key of ['audioProfileDayPT', 'audioProfileDayFT', 'audioProfileNightPT', 'audioProfileNightFT']) {
          if(data[key]) {
            try {
              await device.jsonRpc("blx.setAudioProfile", data[key])
            }
            catch(err) {
              Alert.alert("Device error", err.message)
              errors['audioProfile'] = err
            }
          }
        }

        if(data.timerAudio) {
          try {
            await device.jsonRpc("blx.setTimerAudio", data.timerAudio)
          }
          catch(err) {
            Alert.alert("Device error", err.message)
            errors['timerAudio'] = err
          }
        }

        if(data.timerMagnet) {
          try {
            await device.jsonRpc("blx.setTimerMagnet", data.timerMagnet)
          }
          catch(err) {
            Alert.alert("Device error", err.message)
            errors['timerMagnet'] = err
          }
        }

        // test
        // try {
        //   await device.jsonRpc("blx.methodThatDoesntExist")
        // }
        // catch(err) {
        //   errors['shouldFail'] = err
        // }

        if(Object.keys(errors).length > 0) {
          let err = new Error(`Failed to apply configuration items: ${Object.keys(errors)}`)
          reject(err)
        }

        resolve()
      }
      catch(error) {
        reject(error)
      }
    })()
  })

  return { promise, cancel }
}
