import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Text } from "react-native";
import { DeviceContext } from '../common/DeviceContext';
import { SectionHeader, useStatusBarActivity } from '../ui/RTBUI';
import { BLXSpecialBitMultiSelectListItem } from './common/BLXSpecialBitMultiSelectListItem';
import { BLXSpecialBitSwitchListItem } from './common/BLXSpecialBitSwitchListItem';
import { useBlxSpecialBits } from './protocol/useBlxSpecialBits';
import { BLXScreenContainer } from './BLXScreenContainer';
import { isset } from '../jsutils';
import { SelectItem } from "../SelectItem";

export function BLXVoiceMessagesScreen() {
  const { device } = React.useContext(DeviceContext);
  const { t } = useTranslation();
  const { specialBits, specialBitsRead, specialBitsWrite } = useBlxSpecialBits(device);
  const { setActivity } = useStatusBarActivity();

  const load = async () => {
    setActivity(true);
    try {
      await specialBitsRead();
    }
    catch (error) {
      Alert.alert(t("Device error"), error.message);
    }
    finally {
      setActivity(false);
    }
  };

  React.useEffect(() => {
    if (device)
      load();

    return () => { };
  }, [device]);

  return (
    <BLXScreenContainer onReload={load}>
      <SectionHeader>
        {t("Wait messages")}
      </SectionHeader>

      <BLXSpecialBitSwitchListItem
        title={t('"Wait" on PED/APS call')}
        subtitle="A[8]"
        specialBits={specialBits}
        specialBitsWrite={specialBitsWrite}
        register={0}
        bitIndex={0x08}
        topDivider
        bottomDivider />

      {device?.isNorthAmerica() && (<>
        <BLXSpecialBitSwitchListItem
          title={t('"Wait to cross" on PED call')}
          subtitle="B[8]"
          specialBits={specialBits}
          specialBitsWrite={specialBitsWrite}
          register={1}
          bitIndex={0x08}
          />
        <BLXSpecialBitSwitchListItem
          title={t('"Wait to cross" on APS call')}
          subtitle="B[9]"
          specialBits={specialBits}
          specialBitsWrite={specialBitsWrite}
          register={1}
          bitIndex={0x09}
          bottomDivider
          />
      </>)}

      <SectionHeader>
        {t("APS guide messages")}
      </SectionHeader>

      <BLXSpecialBitMultiSelectListItem
        title={t("Scramble / traveling")}
        subtitle="A[0-1]"
        register={0}
        bitMask={0b11}
        specialBits={specialBits}
        specialBitsWrite={specialBitsWrite}
        items={[
          { label: t('None'), value: 0b00 },
          { label: t('Scramble'), value: 0b01 },
          { label: t('Scramble'), value: 0b11 },
          { label: t('Traveling'), value: 0b10 },
        ]}
        bottomDivider
        topDivider
      />

      <BLXSpecialBitSwitchListItem
        title={t("Direction voice enabled")}
        subtitle="B[B]"
        specialBits={specialBits}
        specialBitsWrite={specialBitsWrite}
        register={1}
        bitIndex={0x0B}
        // topDivider
        bottomDivider />

      <BLXSpecialBitMultiSelectListItem
        title={t("Direction")}
        subtitle="A[3-4]"
        register={0}
        bitMask={0b11000}
        specialBits={specialBits}
        specialBitsWrite={specialBitsWrite}
        items={[
          { label: t('North'), value: 0b01000 },
          { label: t('East'), value: 0b00000 },
          { label: t('South'), value: 0b10000 },
          { label: t('West'), value: 0b11000 },
        ]}
        disabled={!isset(specialBits[1], 0x0B)}
        bottomDivider />

      {/* <DescriptionListItem
              text="TODO: disables bits A[0-1] (scramble/traveling), A[8] (voice wait enabled) und B[B] (direction voice) "
            /> */}

      {/* <Spacer />
            <BLXPlaySoundOnDeviceButton setActivity={setActivity} slot={0} group={0} /> */}

      {/* <DebugView>
        <Spacer />
        <Text>DEBUG:</Text>
        <BLXDebugSpecialBits specialBits={specialBits} />
      </DebugView> */}
    </BLXScreenContainer>
  );
}
