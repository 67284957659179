import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Text, View } from "react-native";
import { Icon, ListItem } from "react-native-elements";
import { DeviceContext } from '../common/DeviceContext';
import { SectionHeader, useStatusBarActivity, Spacer, SwitchListItem } from '../ui/RTBUI';
import { BLXSpecialBitMultiSelectListItem } from './common/BLXSpecialBitMultiSelectListItem';
import { BLXSpecialBitSwitchListItem } from './common/BLXSpecialBitSwitchListItem';
import { useBlxSpecialBits } from './protocol/useBlxSpecialBits';
import { BLXScreenContainer } from './BLXScreenContainer';
import { isset } from '../jsutils';
import { SelectItem } from "../SelectItem";
import { NavListItem } from './common/NavListItem';
import { useNavigation } from '@react-navigation/native';
import { SliderDynamicWidth } from './common/Slider';
import Colors from '../../constants/Colors';

export function BLXDayNightSettingsHKScreen() {
  const { device } = React.useContext(DeviceContext);
  const { t } = useTranslation();

  const load = async() => {
  };

  React.useEffect(() => {
    if(device)
      load()
  }, [device]);

  return (
    <>
      <BLXScreenContainer onReload={load}>
        <Text>TODO: Matrix of day/night delay (Special A Bits: 5-6-7)</Text>
        <Text>TODO: Matrix of BLX Mode  (Special A Bits: 8-9)</Text>
      </BLXScreenContainer>
    </>
  );
}

export function BLXPitSettingsHKScreen() {
  const { device } = React.useContext(DeviceContext);
  const { t } = useTranslation();

  const load = async() => {
  };

  React.useEffect(() => {
    if(device)
      load()
  }, [device]);

  return (
    <>
      <BLXScreenContainer onReload={load}>
        <SectionHeader>LED Brightness Day</SectionHeader>
        <View style={{
            flex: 1, backgroundColor: 'white', flexDirection: 'row', width: '100%', justifyContent: 'center', marginTop: 0, marginBottom: 0
          }}>

          <View style={{ backgroundColor: 'transparent', alignSelf: 'center', width: 32, marginLeft: 10 }}>
            <Icon
              name='moon'
              type="font-awesome-5"
              size={25}
              color='grey' 
              />
          </View>

          <View style={{ backgroundColor: 'transparent', flexGrow: 1, alignSelf: 'center', marginLeft: 15, marginRight: 15 }}>
            <SliderDynamicWidth
              allowTouchTrack
              style={{ marginLeft: 0, marginRight: 0, marginTop: 0, height: 50 }}
              // value={gain}
              // onValueChange={onGainSliderValueChanged}
              // onSlidingComplete={onGainSliderSlidingComplete}
              onSlidingStart={() => {
                console.log("onSlidingStart")
              }}
              minimumValue={0.5}
              maximumValue={15.0}
              step={0.5}
              tapToSeek={true}
              // maximumTrackTintColor='#494A48' 
              minimumTrackTintColor='orange'
              tintColor='orange'
              thumbTintColor={Colors.tintColor}
              // disabled={profile?.muted} 
              />
          </View>

          <View style={{ backgroundColor: 'transparent', alignSelf: 'center', width: 32, marginRight: 12, marginLeft: 2 }}>
            <Icon
              name='sun'
              type="font-awesome-5"
              size={25}
              style={{ width: '100%' }}
              color='grey'
              />
          </View>
        </View>
        
        <SectionHeader>LED Brightness Night</SectionHeader>
        <View style={{
            flex: 1, backgroundColor: 'white', flexDirection: 'row', width: '100%', justifyContent: 'center', marginTop: 0, marginBottom: 0
          }}>

          <View style={{ backgroundColor: 'transparent', alignSelf: 'center', width: 32, marginLeft: 10 }}>
            <Icon
              name='moon'
              type="font-awesome-5"
              size={25}
              color='grey' />
          </View>

          <View style={{ backgroundColor: 'transparent', flexGrow: 1, alignSelf: 'center', marginLeft: 15, marginRight: 15 }}>
            <SliderDynamicWidth
              allowTouchTrack
              style={{ marginLeft: 0, marginRight: 0, marginTop: 0, height: 50 }}
              // value={gain}
              // onValueChange={onGainSliderValueChanged}
              // onSlidingComplete={onGainSliderSlidingComplete}
              onSlidingStart={() => {
                console.log("onSlidingStart")
              }}
              minimumValue={0.5}
              maximumValue={15.0}
              step={0.5}
              tapToSeek={true}
              // maximumTrackTintColor='#494A48' 
              minimumTrackTintColor='orange'
              tintColor='orange'
              thumbTintColor={Colors.tintColor}
              // disabled={profile?.muted} 
              />
          </View>

          <View style={{ backgroundColor: 'transparent', alignSelf: 'center', width: 32, marginRight: 12, marginLeft: 2 }}>
            <Icon
              name='sun'
              type="font-awesome-5"
              size={25}
              style={{ width: '100%' }}
              color='grey' />
          </View>
        </View>        
      </BLXScreenContainer>
    </>
  );
}

export function BLXVoiceMessagesHKScreen() {
  const { device } = React.useContext(DeviceContext);
  const { t } = useTranslation();
  const { specialBits, specialBitsRead, specialBitsWrite } = useBlxSpecialBits(device);
  const { setActivity } = useStatusBarActivity();
  const navigation = useNavigation()

  const [voiceMessageOrder, setVoiceMessageOrder] = React.useState(0)

  const load = async () => {
    setActivity(true);
    try {
      await specialBitsRead();

      if(device.isHongkong()) {
        await readVoiceMessageOrder()
      }

    }
    catch (error) {
      Alert.alert(t("Device error"), error.message);
    }
    finally {
      setActivity(false);
    }
  };

  const readVoiceMessageOrder = async() => {
    try {
      let order = await device.jsonRpc("blx.getVoiceMessageOrder")
      setVoiceMessageOrder(order)
    }
    catch(err) {
      Alert.alert(t("Device error"), err.message)
    }
  }

  const sendVoiceMessageOrder = async(order) => {
    try {
      await device.jsonRpc("blx.setVoiceMessageOrder", order)
    }
    catch(err) {
      Alert.alert(t("Device error"), err.message)
    }

    await readVoiceMessageOrder()
  }

  React.useEffect(() => {
    if (device)
      load();

    return () => { };
  }, [device]);

  return (
    <BLXScreenContainer onReload={load}>

      <SectionHeader>
        {t('Volume profiles')}
      </SectionHeader>

      <ListItem
        title={t("Day")}
        subtitle={device?.isHongkong() ? t("Mode 1") : t("Default profile")}
        subtitleStyle={{ color: 'gray' }}
        onPress={() => navigation.navigate("BLXToneProfile", { mode: "day", tone: "SPM", title: t("SPM volume profile") })}
        leftIcon={{
          type: "font-awesome-5", color: 'gray', style: { width: 30, textAlign: 'center' },
          name: (device?.isHongkong() ? "sun" : "traffic-light"),
          solid: true
        }}
        chevron
        bottomDivider
        topDivider 
        />

      <ListItem
        title={t("Night")}
        subtitle={device?.isHongkong() ? t("Mode 2") : t("Nearby assistive pedestrian")}
        subtitleStyle={{ color: 'gray' }}
        onPress={() => navigation.navigate("BLXToneProfile", { mode: "night", tone: "SPM", title: t("SPM volume profile") })}
        leftIcon={{
          type: "font-awesome-5", color: 'gray', style: { width: 30, textAlign: 'center' },
          name: 'blind',
          name: (device?.isHongkong() ? "moon" : "blind"),
          solid: true
        }}
        chevron
        bottomDivider 
        />


        <SectionHeader>Speakers</SectionHeader>
        <SwitchListItem
          title="Front speaker output"
          topDivider
        />
        <SelectItem
          title={t('Volume adjustment')}
          items={[
            { label: t('0 dB'), value: 0 },
            { label: t('1 dB'), value: 1 },
            { label: t('2 dB'), value: 2 },
            { label: t('...'), value: 3 },
            { label: t('15 dB'), value: 15 },
          ]}
          onValueChange={(val) => {}}
          topDivider
          bottomDivider
        />

        <Spacer />
        <SwitchListItem 
          title="Side speakers output"
          topDivider
          bottomDivider
        />

        <SelectItem
          title={t('Volume adjustment')}
          items={[
            { label: t('0 dB'), value: 0 },
            { label: t('1 dB'), value: 1 },
            { label: t('2 dB'), value: 2 },
            { label: t('...'), value: 3 },
            { label: t('15 dB'), value: 15 },
          ]}
          onValueChange={(val) => {}}
          bottomDivider
        />

        <Text style={{color: '#f08400', margin: 10}}>
          (52: CMD_FADER_LS

          {'\n'}Unschönheit: CMD_FADER_LS steuert die Dämpfungen für PT und SPM zusammen. Wär super wenn wir das in PT, FT und SPM unterteilen könnten.
          )
        </Text>


      <SectionHeader>Sound slots</SectionHeader>

      <NavListItem
        title={t("Slot 1")}
        screen="BLXSoundSlot"
        screenParams={{ slot: 0, title: "Slot 0" }}
        iconName="upload"
        bottomDivider
        topDivider
        />
      <NavListItem
        title={t("Slot 2")}
        screen="BLXSoundSlot"
        screenParams={{ slot: 0, title: "Slot 0" }}
        iconName="upload"
        bottomDivider
        />
      <NavListItem
        title={t("Slot 3")}
        screen="BLXSoundSlot"
        screenParams={{ slot: 0, title: "Slot 0" }}
        iconName="upload"
        bottomDivider
        />

      <Spacer />

      <SelectItem
        title={"Sound playback order"}
        value={voiceMessageOrder}
        onValueChange={(val) => { sendVoiceMessageOrder(val); }}
        items={[
          { label: "1, 2, 3", value: 0x00},
          { label: "1, 3, 2", value: 0x01},
          { label: "2, 1, 3", value: 0x02},
          { label: "2, 3, 1", value: 0x03},
          { label: "3, 1, 2", value: 0x04},
          { label: "3, 2, 1", value: 0x05},
        ]}
        topDivider
        bottomDivider
      />

      <Spacer />

      {/* <DescriptionListItem
              text="TODO: disables bits A[0-1] (scramble/traveling), A[8] (voice wait enabled) und B[B] (direction voice) "
            /> */}

      {/* <Spacer />
            <BLXPlaySoundOnDeviceButton setActivity={setActivity} slot={0} group={0} /> */}

      {/* <DebugView>
        <Spacer />
        <Text>DEBUG:</Text>
        <BLXDebugSpecialBits specialBits={specialBits} />
      </DebugView> */}
    </BLXScreenContainer>
  );
}
