import * as React from 'react';
import { Alert } from 'react-native';
import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

/**
 * Helper that navigates to root view (device list) when a topo device disconnects.
 * 
 * @param {} device 
 */
export function useTopoDisconnectHandler(device, debugName) {
  const navigation = useNavigation();
  const {t} = useTranslation()
  const deviceRef = React.useRef()

  const isFocused = useIsFocused()

  const onDisconnect = (dev, error) => {
    if(device)
      device.onDisconnect = null

    Alert.alert(t("Device error"), t("Device disconnected"));
    navigation.popToTop();
  }

  useFocusEffect(React.useCallback(() => {
    console.log("useTopoDisconnectHandler focused, debugName:", debugName, "device: ", device?.id);
    

    if(device) {
      
      // device.onDisconnect = onDisconnect
      device.onDisconnect = (dev, error) => {
        if(device)
          device.onDisconnect = null
    
        Alert.alert(t("Device error"), t("Device disconnected"));
        navigation.popToTop();
      }
    }
    else {
      console.error("\tno device on focus :(")
    }

    // unfocus
    return () => {
      console.log("useTopoDisconnectHandler unfocus, debugName:", debugName);
      if(device) {
        device.onDisconnect = null
      }
    }
  }, [device]))

  // device changed after focusing
  React.useEffect(() => {
    if(device) {
      deviceRef.current = device
    }

    if(isFocused && device) {
      if(!device.onDisconnect)
        console.error("todo: device changed after focussing, attach disconnect handler!")
    }

  }, [device])

  // component descruct: remove listener
  React.useEffect(() => {
    return () => {
      //if(device)
      //  device.onDisconnect = null
      if(deviceRef.current)
        deviceRef.current.onDisconnect = null
    }
  }, [])
}
