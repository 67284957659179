import Slider from '@react-native-community/slider';
import * as React from 'react';
import { View } from 'react-native';

export function SliderDynamicWidth({wrapViewProps, ...props}) {
  const [parentViewWidth, setParentViewWidth] = React.useState(null)

  return (
    <View 
      {...wrapViewProps}
      onLayout={(event) => {
        console.log("SliderDynamicWidth ONLAYOUT", event.nativeEvent.layout);
        setParentViewWidth(event.nativeEvent.layout.width);
      }
    }>
      {parentViewWidth && (
        <Slider
          style={{...props.style, width: parentViewWidth}}
          {...props}
        />
      )}
    </View>
  )
}