import * as React from 'react';
import Colors from '../constants/Colors';
import { KeyboardAvoidingView, Platform, RefreshControl, ScrollView } from 'react-native';
import { useHeaderHeight, HeaderHeightContext } from '@react-navigation/stack';
import { StatusBar } from 'react-native';


export function ScreenContainer({ onLoad, onReload, ...props }) {
  const [refreshing, setRefreshing] = React.useState(false);

  const headerHeight= React.useContext(HeaderHeightContext) ?? 0
  //const headerHeight = useHeaderHeight()

  React.useEffect(() => {
    console.log("screencontainer onload");
    if (onLoad) {
      onLoad();
    }

    return () => { };
  }, []);

  var refreshControl = null;
  if (onLoad || onReload) {
    refreshControl = (<RefreshControl
      refreshing={refreshing}
      onRefresh={onReload ?? onLoad}
      tintColor={Colors.tintColor}
      colors={[Colors.tintColor]} />);
  }

  // console.log("headerHeight:", headerHeight, 
  //   "statusbar height:", StatusBar.height, 
  //   "statusbar currentHeight:", StatusBar.currentHeight, 
  //   "sum:", headerHeight+StatusBar.currentHeight)
  /*
    KeyboardAvoidingView / keyboard offset is total fucked up in react native.
    See also keyboardHidesTapbar setting in App.js
  */
  let keyboardVerticalOffset = headerHeight;
  if (Platform.OS === 'android') {
    keyboardVerticalOffset += (StatusBar.currentHeight ?? 0);
  }

  // <SafeAreaView edges={['right', 'bottom', 'left']} style={{flex: 1, justifyContent: 'space-between'}} >
  return (
    <>
      {/* <SafeAreaView edges={['right', 'bottom', 'left']} style={{flex: 1, backgroundColor: "lightgrey", justifyContent: 'space-between'}}> */}
      {/* <Text style={{margin: 0, padding: 0, backgroundColor: 'red'}}>asdf23</Text> */}
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS == "ios" ? "padding" : "height"}
        // keyboardVerticalOffset={headerHeight + StatusBar.currentHeight}
        // contentContainerStyle={{backgroundColor: 'yellow'}}
        // keyboardVerticalOffset={170}
        // contentContainerStyle={{backgroundColor: 'green'}}
        // keyboardVerticalOffset={headerHeight + (StatusBar.currentHeight ?? StatusBar.height ?? 0) }
        keyboardVerticalOffset={keyboardVerticalOffset}
      >
        <ScrollView
          // contentContainerStyle={{flexGrow: 1}}
          refreshControl={refreshControl}
        >

          {/* <View style={{height: 4}} /> */}
          {props.children}
          {/* <View style={{height: 3, backgroundColor: 'red'}} /> */}
        </ScrollView>
      </KeyboardAvoidingView>
      {/* </SafeAreaView> */}
    </>
  );
  // {/* </SafeAreaView> */}
}
