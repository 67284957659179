import * as React from 'react';

// helper for blx special bit state / bindings
export function useBlxSpecialBits(device) {
  const [specialBits, setSpecialBits] = React.useState([0, 0, 0]);

  const specialBitsRead = async () => {
    const registers = await device.jsonRpc("blx.getSpecialBits");
    if (!Array.isArray(registers)) {
      throw new Error(`Invalid getSpecialBits response json format: ${JSON.stringify(registers)}`);
    }

    setSpecialBits(registers);
  };
  const specialBitsWrite = async (registers) => {
    console.log("specialBitsWrite", registers);
    await device.jsonRpc("blx.setSpecialBits", registers);
    let newRegisters = await device.jsonRpc("blx.getSpecialBits");
    // if(newRegisters != registers) {
    //   throw new JsonRpcError(`Special bits write/read mismatch. Expected: ${registers}, got: ${newRegisters}`)
    // }
    setSpecialBits(newRegisters);
  };

  return { specialBits, specialBitsRead, specialBitsWrite };
}
