import React from 'react';
import axios from 'axios';
import { Button, Divider, Menu, MenuItem } from '@material-ui/core';
import { setChonkyDefaults, ChonkyActions } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import { FullFileBrowser } from 'chonky';
import { BLXConfigFileModal } from './config/BLXConfigFileModal';
import { Alert } from './screens/ui/Alert';
import { AuthContext, API_BASE_URL, keycloak, AudioFilePreviewModal } from './App';
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { AudioSynthDialog } from './AudioSynthView';
import AddIcon from '@material-ui/icons/Add';

export function NewConfigMenuButton({onSelected}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {t} = useTranslation()

  const onOpenClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = (selectedVariant) => {
    setAnchorEl(null);

    if(selectedVariant) {
      onSelected(selectedVariant)
    }
  };

  return (
    <div>
      <Button aria-controls="new-config-menu" 
        aria-haspopup="true" 
        onClick={onOpenClick}
        variant="contained"
        color="primary"
        style={{marginTop: 20}}
        startIcon={<AddIcon />}
        >
        {t('New Configuration')}
      </Button>

      <Menu
        id="new-config-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => onClose(null)}
      >
        <MenuItem disabled>{t('Device Variant')}</MenuItem>
        <MenuItem onClick={() => onClose("NA")}>BLX North America</MenuItem>
        <MenuItem onClick={() => onClose("QU")}>BLX Quebec</MenuItem>
        <MenuItem onClick={() => onClose("HK")}>BLX Hongkong</MenuItem>
      </Menu>
    </div>
  );

}

export function filename(path) {
  return path.replace(/\/$/, '').split('/').pop()
}

export function FilesScreen() {
  const [currentPrefix, setCurrentPrefix] = React.useState(null);
  const [folderChain, setFolderChain] = React.useState([]);
  const [files, setFiles] = React.useState([]);

  const { auth } = React.useContext(AuthContext);

  const inputFileRef = React.useRef(null);

  const [blxConfigModalFile, setBlxConfigModalFile] = React.useState(null);
  const [blxConfigModalDeviceVariant, setBlxConfigModalDeviceVariant] = React.useState(null);

  const [audioModalFile, setAudioModalFile] = React.useState(null);
  const {t} = useTranslation();

  const history = useHistory()
  const location = useLocation()

  // const routeMatch = useRouteMatch('/files/:path');
  // console.log("routeMatch:", routeMatch)
  // const basePath = location.pathname.startsWith("/config") ? '/config' : ''

  const load = async () => {
    console.log("FilesView load");

    let prefix = location.pathname.replace("/files", '');

    // if(location.pathname.startsWith('/configs'))
    //   prefix = location.pathname.replace("/configs", '');

    if (!prefix.endsWith('/')) {
      prefix += '/';
    }

    console.log("setting prefix: ", prefix);

    setCurrentPrefix(prefix);
  };

  React.useEffect(() => {
    if (!auth || !location || !auth?.loggedIn) {
      console.error("No auth/location");
      return;
    }

    console.log("LOCATION CHANGED", location);
    load();
  }, [location, auth]);

  // React.useEffect(() => {
  //   if (auth?.loggedIn) {
  //     load();
  //   }
  // }, [auth]);

  // React.useEffect(() => {
  //   console.log("currentPrefix changed:", currentPrefix);
  //   if (currentPrefix) {
  //     console.log("todo: set path");
  //     console.log("history", history);

  //     const oldPathname = history.location.pathname;
  //     console.log("old path:", history.location.pathname);

  //     const newPathname = `/files/${currentPrefix}`;
  //     console.log("new path:", newPathname);

  //     if (newPathname !== oldPathname) {
  //       //   history.replace({
  //       //     pathname: newPathname
  //       //   })
  //       // window.history.replaceState(null, "BLX online", newPathname)
  //     }
  //   }
  // }, [currentPrefix]);

  React.useEffect(() => {
    setChonkyDefaults({
      iconComponent: ChonkyIconFA,
      // disableSelection: true,
      clearSelectionOnOutsideClick: true,
    });
    // load()
  }, []);

  const loadPrefix = async(prefix) => {
    console.log("loadPrefix:", prefix);

    if(!prefix) {
      console.error("no prefix");
      setFiles([]);
      setFolderChain([]);
      return;
    }

    const url = `${API_BASE_URL}/fs/list/${prefix}`;
    console.log("list url:", url);

    let res = null;
    try {
      res = await axios.get(url, {
        params: { recursive: false }
      });
      console.log("listRes:", res);
    }
    catch (err) {
      console.error('list err:', err);
      Alert.alert("Error listing objects", err.message);
      return;
    }

    let newFiles = res.data
      .filter(obj => {
        return !filename(obj.object_name).startsWith('.');
      })
      .map(obj => {
        const fname = filename(obj.object_name);
        return {
          ...obj,
          id: obj.object_name,
          name: fname,
          isDir: obj.type === 'prefix',
          modDate: obj.lastModified ? new Date(obj.lastModified * 1000) : undefined,
          isHidden: fname.startsWith("."),
        };
      });
    console.log("newFiles: ", newFiles);

    setFiles(newFiles);

    //let pathComponents = prefix.replace('/configs', '').split("/");
    var pathComponents = prefix.split("/");
    pathComponents = pathComponents.slice(1, pathComponents.length);
    console.log("pathComponents:", pathComponents);

    let pathFolderChain = [];

    // add "base" folder
    if(prefix.startsWith('/configs')) {
      pathFolderChain.push({
        object_name: 'configs',
        id: 'configs',
        name: 'Configurations',
        isDir: true
      });
    }
    else if(prefix.startsWith('/sounds')) {
      pathFolderChain.push({
        object_name: 'sounds',
        id: 'sounds',
        name: 'Sounds',
        isDir: true
      });
    }
    else if(prefix.startsWith('/firmwares')) {
      pathFolderChain.push({
        object_name: 'firmwares',
        id: 'firmwares',
        name: 'Firmwares',
        isDir: true
      });
    }
    else {
      pathFolderChain.push({
        object_name: '/',
        id: '/',
        name: 'Library',
        isDir: true
      });
    }

    // subfolders
    if (pathComponents.length > 1) {
      for (var i = 1; i < pathComponents.length-1; ++i) {
        const subPath = pathComponents.slice(0, i + 1).join('/') + '/';
        console.log(i, "subpath:", subPath);

        pathFolderChain.push({
          object_name: subPath,
          id: subPath,
          name: pathComponents[i],
          isDir: true
        });
      }
    }

    console.log("setFolderChain:", pathFolderChain);
    setFolderChain(pathFolderChain);
  };

  const reload = async () => {
    loadPrefix(currentPrefix);
  };

  React.useEffect(() => {
    console.log("prefix changed: ", currentPrefix);
    loadPrefix(currentPrefix);
  }, [currentPrefix]);

  const download = async (path) => {
    const url = `${API_BASE_URL}/fs/files/${path}`;
    const fetchRes = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${keycloak.token}`
      }
    });
    console.log("fetchRes:", fetchRes);

    const blob = await fetchRes.blob();
    const blobUrl = window.URL.createObjectURL(new Blob([blob]));
    console.log("blobURL:", blobUrl);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute(
      'download',
      filename(path)
    );
    // Append to html link element page
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const deleteFiles = async (objects) => {
    const paths = objects.map(obj => obj.object_name);
    if (!window.confirm("Do you really want to delete: \n\n" + paths.join("\n") + "\n")) {
      return;
    }

    for (let path of paths) {
      if (path.lenght < 3)
        continue;

      console.log(`${API_BASE_URL}/fs/files/${path}`);
      try {
        await axios.delete(`${API_BASE_URL}/fs/files/${path}`);
      }
      catch (err) {
        Alert.alert(err.message);
      }
    }
    await reload();
  };

  const upload = async () => {
    inputFileRef.current.click();
  };

  const onFileInputChange = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    for (let file of event.target.files) {
      // const file = event.target.files[0]
      const fname = file.name;
      console.log("file:", file);

      let formData = new FormData();
      formData.append('file', file);

      const url = `${API_BASE_URL}/fs/files/${currentPrefix}/${fname}`;
      console.log("url:", url);

      const uploadRes = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log("uploadRes:", uploadRes);
    }

    await reload();
  };

  const createFolder = async (action) => {
    const folderName = prompt("Folder name");
    if (!folderName)
      return;

    console.log("folderName:", folderName);

    let formData = new FormData();
    var blob = new Blob([''], { type: "text/plain" });
    formData.append('file', blob);

    const url = `${API_BASE_URL}/fs/files/${currentPrefix}${folderName}/.dir`;
    console.log("url:", url);

    const uploadRes = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    console.log("uploadRes:", uploadRes);

    await reload();
  };

  const onOpenFiles = async (action) => {
    const { targetFile, files } = action.payload;
    console.log(targetFile, files);
    if (!targetFile) {
      console.error("onOpenFiles: no targetFile");
      return;
    }

    if (targetFile.isDir) {
      //setCurrentPrefix(old => old + targetFile.name + '/')
      //setCurrentPrefix(targetFile.object_name)
      history.push("/files/" + encodeURI(targetFile.object_name));
    }
    else if (targetFile.object_name?.includes('.blxc')) {
      setBlxConfigModalFile(targetFile.object_name);
    }
    else if (targetFile.object_name?.includes('.wav')) {
      setAudioModalFile(targetFile);
    }
  };

  const fileActionHandler = async (action) => {
    console.log("fileActionHandler:", action);

    if (action.id === ChonkyActions.OpenFiles.id) {
      console.log("open files!");
      onOpenFiles(action);
    }
    else if (action.id === ChonkyActions.MoveFiles.id) {
      Alert.alert("todo: move support");
    }
    else if (action.id === ChonkyActions.CreateFolder.id) {
      createFolder();
    }
    else if (action.id === ChonkyActions.DownloadFiles.id) {
      const files = action.state.selectedFilesForAction;
      for (let f of files) {
        download(f.object_name);
      }
    }
    else if (action.id === ChonkyActions.UploadFiles.id) {
      upload();
    }
    else if (action.id === ChonkyActions.DeleteFiles.id) {
      const files = action.state.selectedFilesForAction;
      deleteFiles(files);
    }
  };

  const onNewConfigClick = async(variant) => {
    setBlxConfigModalDeviceVariant(variant);
    setBlxConfigModalFile(currentPrefix);
  }
  const onBlxConfigFileModalClose = async() => {
    setBlxConfigModalFile(null);
    setBlxConfigModalDeviceVariant(null);
    await reload()
  }

  return (
    <div style={{ height: '80vh', marginTop: 40, width: '100%' }}>

      <BLXConfigFileModal
        open={blxConfigModalFile !== null}
        filePath={blxConfigModalFile}
        deviceVariant={blxConfigModalDeviceVariant}
        onClose={onBlxConfigFileModalClose}
        />

      <AudioFilePreviewModal
        open={audioModalFile !== null}
        file={audioModalFile}
        onClose={() => {
          setAudioModalFile(null);
        }}
        />

      <FullFileBrowser
        files={files}
        folderChain={folderChain}
        defaultFileViewActionId={ChonkyActions.EnableListView.id}
        defaultSortActionId
        onFileAction={fileActionHandler}
        fileActions={[
          ChonkyActions.UploadFiles,
          ChonkyActions.DownloadFiles,
          ChonkyActions.DeleteFiles,
          ChonkyActions.CreateFolder
        ]}
        />

      {/* invisible input used for file uploads */}
      <input
        type='file'
        id='file'
        ref={inputFileRef}
        onChange={onFileInputChange}
        style={{ display: 'none' }}
        multiple
      />

      {currentPrefix?.startsWith("/configs") && (<>
        <NewConfigMenuButton
          onSelected={onNewConfigClick}
           />
      </>)}

      {currentPrefix?.startsWith("/sounds") && (<>
        <div style={{marginTop: 20}}>
          <AudioSynthDialog
            currentPrefix={currentPrefix}
            onClose={reload}
          />

          <Button
            style={{marginLeft: 20}}
            onClick={() => upload()}
            color="primary"
            variant="contained"
          >
          {t("Upload")}
          </Button>
        </div>
      </>)}

      {/* <pre>
        prefix: {currentPrefix}
      </pre>
      <pre>
        folderChain: {JSON.stringify(folderChain, null, 4)}
      </pre>
      <pre>
        files: {JSON.stringify(files, null, 4)}
      </pre>  */}
      

    </div>
  );
}
