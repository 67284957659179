
import * as React from 'react';
import { View } from 'react-native';
import { Divider, ListItem, Text } from 'react-native-elements';

import { colors } from 'react-native-elements'
import { SwitchListItem } from '../ui/RTBUI';
import { useNavigation } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';


// FIXME: https://github.com/react-native-elements/react-native-elements/pull/2561

// @ts-ignore The typings are also missing "web"
if (colors.platform.web == null) {
  // @ts-ignore The typings are also missing "web"
  colors.platform.web = {
    primary: '#2089dc',
    secondary: '#ca71eb',
    grey: '#393e42',
    searchBg: '#303337',
    success: '#52c41a',
    error: '#ff190c',
    warning: '#faad14'
  }
}

export function BLXTestScreenForBLXOnline() {
  const [switchVal, setSwitchVal] = React.useState(false)
  const navigation = useNavigation()

  return (
    <View>
      <Text>Text</Text>

      <Divider style={{height: 30}} />

      <Text h3 style={{marginVertical: 20}}>ListItem</Text>

      <ListItem 
        title="asdf"
        topDivider
        bottomDivider
        chevron
        onPress={() => {
          //alert("asdf")
          navigation.navigate("Home")
        }}
      />

      <Text h3 style={{marginVertical: 20}}>Switch</Text>

      <SwitchListItem
        value={switchVal}
        onValueChange={setSwitchVal}
        title="asdf"
        subtitle="subtitle"
        leftIcon={{type: "font-awesome-5", color: 'gray', style: {width: 30, textAlign: 'center'}, name: 'upload'}}
        topDivider
        bottomDivider
        />

      <Text>
        switchVal: {JSON.stringify(switchVal)}
      </Text>

      <Text h3 style={{marginVertical: 20}}>Input</Text>

      <ListItem
        topDivider
        bottomDivider>
        <ListItem.Content>
          <ListItem.Title>
            title
          </ListItem.Title>
          <ListItem.Subtitle>
            subtitle
          </ListItem.Subtitle>
        </ListItem.Content>
        <ListItem.Input
          value="asdf"
        />
      </ListItem>

    </View>
  )
}