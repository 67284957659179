import React from 'react';
import { BLXTestScreenForBLXOnline } from '../screens/blx/BLXTestScreenForBLXOnline';
import { NavigationContainer, StackActions, useRoute } from '@react-navigation/native';
import { DeviceContext } from '../screens/common/DeviceContext';
import { BLXVoiceMessagesScreen } from '../screens/blx/BLXVoiceMessagesScreen';
import { BLXDayNightSettingsHKScreen, BLXPitSettingsHKScreen, BLXVoiceMessagesHKScreen } from '../screens/blx/BLXVoiceMessagesHKScreen';
import { BLXToneProfileScreen } from '../screens/blx/BLXToneProfileScreen';
import { createStackNavigator } from '@react-navigation/stack';
import { Text, Platform, View } from 'react-native';
import { BLXSpecialBitScreen } from '../screens/blx/BLXSpecialBitScreen';
import { BLXWalkScreen } from '../screens/blx/BLXWalkScreen';
import { BLXPilotScreen } from '../screens/blx/BLXPilotScreen';
import { BLXClearanceScreen } from '../screens/blx/BLXClearanceScreen';
import { BLXPedSettingsScreen } from '../screens/blx/BLXPedSettingsScreen';
import { BLXApsSettingsScreen } from '../screens/blx/BLXApsSettingsScreen';

import { CircularProgress, Drawer, ListItemText, ListItem as MUIListItem, Divider, Grid, useMediaQuery } from '@material-ui/core';
import { Icon, ListItem, ThemeProvider } from 'react-native-elements';
import Colors from '../constants/Colors';
import { ScrollView } from 'react-native-gesture-handler';
import { LocIDSettingsScreen } from './LocIDSettingsScreen';

//const RNStack = createStackNavigator()
//const RNDrawer = createDrawerNavigator();
const Stack = createStackNavigator()

const rnElementsTheme = {
  Switch: {
    trackColor: { true: 'orange', false: Platform.OS=='android' ? '#d3d3d3' : '#fbfbfb' },
  },
  Text: {
    style: {
      fontFamily: 'Roboto',
    },
  },
  ListItemTitle: {
    style: {
      fontFamily: 'Roboto',
      fontSize: 15
    }
  },
  ListItem: {
    titleStyle: { fontFamily: 'Roboto', },
    subtitleStyle: { fontFamily: 'Roboto', }
  },
  ListItemInput: {
    style: { fontFamily: 'Roboto', },
  }
}

function DrawerSectionHeader({title}) {
  return (
    <Text
    style={{marginLeft: 16, marginBottom: 10, marginTop: 20, color: 'rgb(100,100,100)', fontFamily: 'Roboto'}}
  >{title}</Text> 
  )
}

function DrawerNavItem({title, navigationRef, screen, screenParams={}, icon, topDivider, selected}) {
  
  return (
    <ListItem
      onPress={() => {
        navigationRef.current.dispatch(StackActions.replace(screen, screenParams))
      }}
      containerStyle={{paddingTop: 12, paddingBottom: 12, backgroundColor: selected ? 'rgb(230,230,230)' : 'white'}}
    >
    <Icon type="font-awesome-5" name={icon} color='grey' style={{width: 25, textAlign: 'center'}} solid />
    <ListItem.Content>
      <ListItem.Title style={{fontSize: 15, fontFamily: 'Roboto'}}>
        {title}
      </ListItem.Title>
    </ListItem.Content>
    <ListItem.Chevron />
  </ListItem>
  )
}

function ConfigRawScreen() {
  const route = useRoute()
  const config = route?.params?.config

  return (
    <ScrollView>
      <Text>
        {config ? JSON.stringify(config, null, 4) : 'no config' }
      </Text>
    </ScrollView>
  )
}

function HomeScreen() {
  return (
    <View>
    </View>
  )
}

export function BLXConfigEditView({device, configFileContent}) {
  const drawerContainerRef = React.useRef()
  const rnNavigationRef = React.useRef()

  const [selectedScreenName, setSelectedScreenName] = React.useState(null)

  // React.useEffect(() => {
  //   console.log("rnNavigationRef useEffect", rnNavigationRef.current)
  //   if(rnNavigationRef.current) {
  //     rnNavigationRef.current.addListener('focus', (evt) => {
  //       console.log("nav focus changed!", evt)
  //     })
  //     rnNavigationRef.current.addListener('blur', (evt) => {
  //       console.log("nav focus blur!", evt)
  //     })
  //     rnNavigationRef.current.addListener('state', (evt) => {
  //       console.log("nav focus state change!", evt)
  //     })
  //   }
  // }, [rnNavigationRef])

  const onNavigationStateChange = (state) => {
    console.log("onNavigationStateChange: ", state)

    if(state?.routes?.length > 0) {
      const rootScreenName = state.routes[state.routes.length-1].name
      console.log("rootScreenName:", rootScreenName)
      setSelectedScreenName(rootScreenName)
    }
    else {
      setSelectedScreenName(null)
    }
  }

  if(!device) {
    return (
      <Grid alignItem="center" justify="center" container>
        <CircularProgress />
      </Grid>
    )
  }

  const drawerWidth = 250;

  return (
    <ThemeProvider theme={rnElementsTheme}>
    <NavigationContainer 
      ref={rnNavigationRef} 
      onStateChange={onNavigationStateChange}
      >

    <div 
      ref={drawerContainerRef}
      style={{ height: 700, width: "100%", minWidth: 700, backgroundColor: 'white', position: "relative"}}>

      <Drawer
        variant="permanent"
        open={true}
        // style={{width: 250, zIndex: 1, flexShrink: 0}}
        BackdropProps={{ style: { position: 'absolute' } }}
        // anchor="left"
        ModalProps={drawerContainerRef.current ? {
          container: drawerContainerRef.current,
          style: { position: 'absolute' }
          } : {}}
        PaperProps={{ style: { position: "absolute", width: drawerWidth } }}
      >

        <DrawerSectionHeader title="Phases" />
        <Divider />

        <DrawerNavItem
          title="Don't walk"
          screen="BLXPilotScreen"
          icon="compass"
          navigationRef={rnNavigationRef}
          selected={selectedScreenName === "BLXPilotScreen"}
          />
        <DrawerNavItem
          title="Walk"
          screen="BLXWalkScreen"
          icon="walking"
          navigationRef={rnNavigationRef}
          selected={selectedScreenName === "BLXWalkScreen"}
          />

        {device?.isHongkong() && <>
          <DrawerNavItem
            title="Voice messages"
            screen="BLXVoiceMessagesHKScreen"
            icon="microphone"
            navigationRef={rnNavigationRef}
            selected={selectedScreenName === "BLXVoiceMessagesHKScreen"}
            />

          <Divider style={{marginTop: 50}} />

          <DrawerNavItem
            title="Push button"
            screen="BLXPitSettingsHKScreen"
            // icon="microphone"
            icon="hand-paper"
            navigationRef={rnNavigationRef}
            selected={selectedScreenName === "BLXPitSettingsHKScreen"}
            />

          <DrawerNavItem
            title="Day/night"
            screen="BLXDayNightSettingsHKScreen"
            icon="moon"
            navigationRef={rnNavigationRef}
            selected={selectedScreenName === "BLXDayNightSettingsHKScreen"}
            />


        </>}

        {(device?.isNorthAmerica() || device?.isQuebec()) && <>
          <DrawerNavItem
            title="Clearance"
            screen="BLXClearanceScreen"
            icon="broom"
            navigationRef={rnNavigationRef}
            selected={selectedScreenName === "BLXClearanceScreen"}
            />
          <DrawerSectionHeader title="Voice Feedback" />
          <Divider />

          <DrawerNavItem
            title="Voice messages"
            screen="BLXVoiceMessagesScreen"
            icon="microphone"
            navigationRef={rnNavigationRef}
            selected={selectedScreenName === "BLXVoiceMessagesScreen"}
            />
        </>}

        <DrawerSectionHeader title="Triggers" />
        <Divider />

        {(device?.isNorthAmerica() || device?.isQuebec()) && <>
        <DrawerNavItem
          title="PED button"
          screen="BLXPedSettings"
          icon="hand-paper"
          navigationRef={rnNavigationRef}
          selected={selectedScreenName === "BLXPedSettings"}
          />
        <DrawerNavItem
          title="APS button"
          screen="BLXApsSettings"
          icon="compass"
          navigationRef={rnNavigationRef}
          selected={selectedScreenName === "BLXApsSettings"}
          />
        </>}

        <DrawerNavItem
          title="LOC.id"
          screen="BLXLocID"
          icon="mobile-alt"
          navigationRef={rnNavigationRef}
          selected={selectedScreenName === "BLXLocID"}
          />

        <DrawerSectionHeader title="Debug" />
        <Divider />

        <DrawerNavItem
          title="Raw"
          screen="raw"
          screenParams={{config: configFileContent}}
          icon="code"
          navigationRef={rnNavigationRef}
          selected={selectedScreenName === "raw"}
          />

      </Drawer>

      <DeviceContext.Provider value={{ device }}>
        <View style={{flex: 1, height: "100%", backgroundColor: 'blue', marginLeft: drawerWidth}}>
          <Stack.Navigator
            initialRouteName="home"
            // openByDefault={true}
            // drawerType="permanent"
            // drawerPosition="left"
            // drawerStyle={{ 
            //   height: 1000, 
            //   width: 200,
            // }}
            // sceneContainerStyle={{
            //   backgroundColor: 'yellow'
            // }}
            screenOptions={({navigation, route}) => {
              return {
                //headerLeft: () => <Text>LEFT</Text>
                // headerBackTitle: "LALALA",
                headerStyle: {
                  height: 50
                },
                headerBackTitleVisible: false,
                headerTitle: route?.params?.title,
                headerBackImage: () => <Icon 
                  type="font-awesome-5" 
                  name="chevron-left" 
                  color={Colors.tintColor}
                  />
              }
            }}
          >

            <Stack.Screen 
              name="home" 
              component={HomeScreen} 
              options={{ 
                drawerLabel: 'Home',
                header: () => null
              }}
              />

            <Stack.Screen name="raw" component={ConfigRawScreen}  options={{title: "Raw"}} />

            <Stack.Screen name="BLXToneProfile" component={BLXToneProfileScreen}  options={{title: "Tone profile"}} />
            <Stack.Screen name="BLXVoiceMessagesScreen" component={BLXVoiceMessagesScreen} options={{title: "Voice messages"}} />
            <Stack.Screen name="BLXVoiceMessagesHKScreen" component={BLXVoiceMessagesHKScreen} options={{title: "Voice messages"}} />

            <Stack.Screen name="BLXPilotScreen" component={BLXPilotScreen} options={{header: () => null}} />
            <Stack.Screen name="BLXWalkScreen" component={BLXWalkScreen} options={{title: "Walk"}} />
            <Stack.Screen name="BLXClearanceScreen" component={BLXClearanceScreen} options={{title: "Clearance"}} />

            <Stack.Screen name="BLXPedSettings" component={BLXPedSettingsScreen} options={{title: "PED Button"}} />
            <Stack.Screen name="BLXApsSettings" component={BLXApsSettingsScreen} options={{title: "APS Button"}}  />
            <Stack.Screen name="BLXLocID" component={LocIDSettingsScreen} options={{title: "LOC.id"}} />

            <Stack.Screen name="BLXPitSettingsHKScreen" component={BLXPitSettingsHKScreen} options={{title: "Push button"}} />
            <Stack.Screen name="BLXDayNightSettingsHKScreen" component={BLXDayNightSettingsHKScreen} options={{title: "Day / night"}} />
            
            <Stack.Screen name="Special Bits A" >
              {() => (<BLXSpecialBitScreen route={{params: {register: 'A'}}} />)}
            </Stack.Screen>
            <Stack.Screen name="Special Bits B">
              {() => (<BLXSpecialBitScreen route={{params: {register: 'B'}}} />)}
            </Stack.Screen>
            {/* <Stack.Screen name="Raw">
              {() => (<ConfigRawScreen config={config} />)}
            </Stack.Screen> */}
            <Stack.Screen name="UI Test">
              {() => (<BLXTestScreenForBLXOnline />)}
            </Stack.Screen>

          </Stack.Navigator>
          {/* </div> */}

          {/* </RNStack.Navigator> */}
        </View>
      </DeviceContext.Provider>
    </div>
    </NavigationContainer>
    </ThemeProvider>
  );
}
