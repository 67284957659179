import * as React from 'react';

// Context for passing device instances to subcomponent trees
export const DeviceContext = React.createContext();

export function useDevice() {
  const deviceContext = React.useContext(DeviceContext)
  if(!deviceContext) {
    console.error("DeviceContext was not initialized!")
    return null
  }

  return deviceContext?.device
}

export function DeviceContextProvider({children}) {
  const [device, setDevice] = React.useState()

  return (
    <DeviceContext.Provider value={device, setDevice}>
      {children}
    </DeviceContext.Provider>
  )
}