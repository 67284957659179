import * as React from 'react';
import { Alert } from "react-native";
import { useAppSettings } from '../../common/useAppSettings';
import { SelectItem } from "../../SelectItem";

// determines the amount of right shifts for a bit mask / trailing zeros
// e.g. 11000 returns 3
function bitMaskLeftShiftAmount(bitMask) {
  for(let i = 0; i < 32; ++i) {
    if((bitMask >> i) & 0x01 === 0x01)
      return i
  }
  return undefined
}

export function BLXSpecialBitMultiSelectListItem({ title, register, bitMask, specialBits, specialBitsWrite, items, ...props }) {
  const [value, setValue] = React.useState(null);
  const {appSettings} = useAppSettings()

  React.useEffect(() => {
    console.log("BLXSpecialBitMultiSelectListItem specialBits effect, bits:", specialBits[register]?.toString(2), "bitmask:", bitMask.toString(2), " current value:", value?.toString(2));
    console.log("\tleft shift amount:", bitMaskLeftShiftAmount(bitMask));
    console.log("\tnew value: ", (specialBits[register] & bitMask).toString(2));

    setValue(specialBits[register] & bitMask);
  }, [specialBits]);

  const onSelectItemValueChange = async(newVal) => {
    console.log("BLXSpecialBitMultiSelectListItem onSelectItemValueChange, val:", newVal, "from:", value, "bin:", newVal?.toString(2));
    console.log("special bits:", specialBits)
    console.log("items:", items);

    if(newVal === null) {
      console.log("onSelectItemValueChange: newVal is null");
      return;
    }
    if(value === null) {
      console.log("onSelectItemValueChange: value is null");
      return;
    }


    const oldValue = value;
    setValue(newVal);

    // clear bits
    let newBits = [...specialBits];
    newBits[register] &= ~bitMask;
    newBits[register] |= newVal;

    if(specialBits && newBits[register] !== specialBits[register]) {
      console.log("special bits changed, from:", specialBits, " to:", newBits)
      console.log("writing new special bits:", newBits[register].toString(2));

      // send to device, on success keep value
      // on error return to old value
      try {
        await specialBitsWrite(newBits);
      }
      catch(err) {
        console.error("specialBitsWrite err:", err)
        setValue(oldValue);
        Alert.alert("Device Error", err.message);
      }
    }
    else {
      console.log("special bits didn't change")
    }
  };

  return (
    <SelectItem
      title={title}
      subtitleStyle={{color: "grey"}}
      value={value}
      onValueChange={(val) => { onSelectItemValueChange(val); }}
      items={items}
      {...props}
      subtitle={appSettings?.developerMode ? props.subtitle : undefined }
      />
  );
}
