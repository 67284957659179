import * as React from 'react';
import { Text } from "react-native-elements";
import { useDevice } from '../common/DeviceContext';
import { ScreenContainer } from "../ScreenContainer";
import { useTopoDisconnectHandler } from '../topo/useTopoDisconnectHandler';

export function BLXScreenContainer({ children, onLoadOrReload, ...props }) {
  const device = useDevice();

  useTopoDisconnectHandler(device, "blxscreencontainer");

  React.useEffect(() => {
    if(onLoadOrReload && device) {
      console.log("todo: call onloadorreload when device is set");
    }
  }, [device]);

  if (!device) {
    return (<Text>no device</Text>);
  }

  return (
    <ScreenContainer {...props}>
      {children}
    </ScreenContainer>
  );
}
