import { rfc7464Encode } from '../screens/blx/protocol/JsonRpcUtils';
import { BLXSerialMock } from '../screens/blx/protocol/mocks/BLXSerialMock';


export class BLXWebPseudoDevice {
  blxVersionText = null

  constructor(blxVersionText) {
    this.blxVersionText = blxVersionText

    if(this.blxVersionText.includes(" HK")) {
      this.serial = new BLXSerialMock("mock://blx.hk", 0);
    }
    if(this.blxVersionText.includes(" QU")) {
      this.serial = new BLXSerialMock("mock://blx.qu", 0);
    }
    else {
      this.serial = new BLXSerialMock("mock://blx.na", 0);
    }

    this.serial.onReceive = this.onSerialReceive.bind(this);
    this.serial.connect();
  }

  isNorthAmerica() {
    // todo: use some kind of firmware call
    //return this.uri?.includes('na')
    return this.blxVersionText?.includes(" NA") || this.blxVersionText?.includes(" CA")
  }
  isQuebec() {
    // todo: use some kind of firmware call
    //return this.uri?.includes('quebec')
    return this.blxVersionText?.includes(" QU")
  }
  isHongkong() {
    // todo: use some kind of firmware call
    return this.blxVersionText?.includes(" HK")
  }

  onSerialReceive(buf) {
    console.log("onSerialReceive:", buf);

    const rsIndex = buf.indexOf('1E', 0, 'hex');
    const lfIndex = buf.indexOf('0A', 0, 'hex');

    if (rsIndex >= 0 && lfIndex >= 0) {
      const cmdJsonStr = buf.slice(rsIndex + 1, lfIndex).toString('utf-8');
      console.log("cmdJsonStr:", cmdJsonStr);
      const j = JSON.parse(cmdJsonStr);

      const resolveFunc = this.promiseResolves[j.id];
      if (resolveFunc)
        resolveFunc(j.result);

      else
        console.error("NO PROMISERESOLVE");
    }
  }

  promiseResolves = {};
  jsonRpcId = 0;

  async jsonRpc(method, params) {
    console.log("jsonrpc:", method, params);

    this.jsonRpcId += 1;

    const requestJson = {
      "jsonrpc": "2.0",
      method: method,
      id: this.jsonRpcId
    };
    if (params) {
      requestJson['params'] = params;
    }

    const requestJsonStr = JSON.stringify(requestJson);
    // const requestJsonBuf = Buffer.from(requestJsonStr, 'utf-8')
    const requestJsonBuf = rfc7464Encode(requestJsonStr);
    await this.serial.write(requestJsonBuf);

    return new Promise((resolve, reject) => {
      this.promiseResolves[this.jsonRpcId] = resolve;
    });
  }
}
