import { rfc7464Encode } from "../JsonRpcUtils";
import { Serial } from "../Serial";
import { Buffer } from 'buffer';

/**
 * Mockup of the upcoming "nordic serial" blx jsonrpc protocol
 */
export class BLXSerialMock implements Serial {
  connected = false
  safetyMode = true
  loggedIn = false
  remainingPinAttempts = 4
  receiveBuf = Buffer.alloc(0)
  specialBits = [ 17509, 57480, 33152 ]
  versionText = "0.0.0. mockup HK"
  audioProfiles : {[key: string] : any} = {}
  pin : string|null = "1212"
  errorCounters : {[key: string] : number} = { "F1": 0, "F2": 0, "F3": 0, "F4": 0, "F5": 0, "F7": 0, "F8": 0, "F9": 0, "F11": 0, "F13": 0 }
  timerAudio = 5
  timerMagnet = 8
  walkTone = 'chirp_voice'
  uri : string
  onReceive: ((data: Buffer) => void) | undefined
  commandDelayMS = 50
  voiceMessageOrder = 0
  speakerConfiguration = 0
  speakerGains : {[key: number] : number} =  { 0: 0, 1: 0, 2: 0, 3: 0 }
  pitLedBrightness : {[key: string] : number} = { 'day': 0, 'night':  0}
  apsGain = 0
  switchToneVariant = 0

  setDefaults() {
    this.connected = false
    this.safetyMode = true
    this.loggedIn = false
    this.remainingPinAttempts = 4
    this.specialBits = [ 17509, 57480, 33152 ]
    this.audioProfiles = {}
    this.errorCounters = { "F1": 0, "F2": 0, "F3": 0, "F4": 0, "F5": 0, "F7": 0, "F8": 0, "F9": 0, "F11": 0, "F13": 0 }
    this.timerAudio = 5
    this.timerMagnet = 8
    this.walkTone = 'chirp_voice'
    this.voiceMessageOrder = 0
    this.speakerConfiguration = 0
    this.speakerGains = { 0: 0, 1: 0, 2: 0, 3: 0 }
    this.pitLedBrightness = { 'day': 0, 'night':  0}
    this.apsGain = 0
    this.switchToneVariant = 0
  }

  constructor(uri : string, commandDelayMS : number|undefined) {
    console.log("BLXSerialMock uri:", uri)
    this.uri = uri

    if(uri.includes("blx.na")) {
      this.versionText = "MOCK SRC_99b9  BLX CA\n\n"
    }
    else if(uri.includes("blx.quebec")) {
      this.versionText = "MOCK SRC_99b9  BLX QU\n\n"
    }
    else if(uri.includes("blx.hk")) {
      this.versionText = "MOCK SRC_99b9  BLX HK\n\n"
    }

    if(uri.includes('pin=0')) {
      this.pin = null
    }
    if(commandDelayMS) {
      this.commandDelayMS = commandDelayMS
    }
  }

  async connect() {
    this.connected = true
  }

  async disconnect() {
    this.connected = false
  }


  write(data: Buffer) : Promise<void> {
    // console.log("serial mock write: ", data);
    if(!this.connected) {
      throw new Error("Serial (mock) is not connected")
    }

    this.receiveBuf = Buffer.concat([this.receiveBuf, data])

    let rsIndex = this.receiveBuf.indexOf('1E', 0, 'hex')
    let lfIndex = this.receiveBuf.indexOf('0A', 0, 'hex')
    console.log("rsIndex", rsIndex, "lfIndex", lfIndex)

    if(rsIndex < 0 || lfIndex < 0) {
      console.log("buffer underrun, waiting for more data")
      return new Promise<void>((resolve, _) => {resolve()})
    }

    let cmdJsonStr = this.receiveBuf.slice(rsIndex+1, lfIndex).toString('utf-8')
    console.log("cmdJsonStr:", cmdJsonStr)
    this.receiveBuf = this.receiveBuf.slice(lfIndex+1)
    console.log("receiveBuf after slice:", this.receiveBuf.toString('hex'), "len:", this.receiveBuf.length)

    let request = JSON.parse(cmdJsonStr)
    console.log("serial mock json request: ", request);

    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        if (this.onReceive) {
          //let res = {result: "todo", id: request.id}
          var res : any = { id: request.id, error: { code: -32601, message: `Method not found: ${request.method}` } };
          if (request.method === "locid.getNearThreshold") {
            res = { id: request.id, result: 5 };
          }
          else if (request.method === "locid.ping") {
            res = { id: request.id, result: {"success": "true"} };
          }
          else if (request.method === "blx.logout") {
            res = { id: request.id, result: {"success": "true"} };
          }
          else if (request.method === "blx.login") {
            if(this.remainingPinAttempts <= 0) {
              res = { id: request.id, error: { code: -12345, message: `Auth error. Requires 2FA` } }
            }
            else if(request.params.pin === this.pin) {
              res = { id: request.id, result: {} };
            }
            else {
              this.remainingPinAttempts -= 1
              res = { id: request.id, error: { code: -12345, message: `Invalid pin` } }
            }
          }
          else if(request.method === "blx.getVersion") {
            res = { id: request.id, result: {
              "versionText": this.versionText,
              "eepromVersion": "0.9999",
              "configVersion": "0",
              "irdaVersion": "1"
            } }; 
          }
          else if(request.method === "blx.getSystemInfo") {
            let systemType = "BLX_NA"

            if(this.uri.includes("blx.na")) {
              systemType = "BLX_NA"
            }
            else if(this.uri.includes("blx.quebec")) {
              systemType = "BLX_QU"
            }
            else if(this.uri.includes("blx.hk")) {
              systemType = "BLX_HK"
            }

            res = { id: request.id, result: {
              "LOC.id": true, 
              "protocolVersion": "V0.0.17", 
              "systemType": systemType,
            }};
          }

          else if(request.method === "blx.getErrorCounter") {
            res = { id: request.id, result: this.errorCounters };
          }
          else if(request.method === "blx.resetErrorCounter") {
            for(let key in this.errorCounters) {
              this.errorCounters[key] = 0
            }
            res = { id: request.id, result: { "success": true } };
          }
          else if(request.method === "blx.eraseError") {
            res = { id: request.id, result: {"foo": 0, "bar": 1} };
          }
          else if(request.method === "blx.restoreDefaultConfig") {
            this.setDefaults()
            res = { id: request.id, result: {} };
          }
          else if(request.method === "blx.getSpecialBits") {
            res = { id: request.id, result: this.specialBits };
          }
          else if(request.method === "blx.setSpecialBits") {
            this.specialBits = request.params
            res = { id: request.id, result: this.specialBits };
          }
          else if(request.method === "blx.getAudioProfile") {
            let prof = this.audioProfiles[`${request.params.mode}.${request.params.tone}`] ?? 
              {"mode": request.params.mode, "tone": request.params.tone, "minValue": 30, "maxValue": 90, "gain": 3, "dropSpeed": "normal"}

            res = { id: request.id, result: prof }
          }
          else if(request.method === "blx.setAudioProfile") {
            this.audioProfiles[`${request.params.mode}.${request.params.tone}`] = request.params
            res = { id: request.id, result:{} }
          }
          else if(request.method === "blx.muteAudioProfile") {
            //params = {"mode": mode_str, "tone": tone_str}
            console.log("TODO: mute. request:", request)
            res = {
              id: request.id,
              result: {"status": "success"}
            }
          }
          else if(request.method === "sound.playSound") {
            res = {
              id: request.id,
              result: {"status": "success"}
            }
          }
          else if(request.method === "sound.prepare") {
            const slot = request.params.slot
            if((slot === 0 || slot === 1) && !this.safetyMode) {
              res = {
                id: request.id,
                error: { code: -12345, message: `Auth error. Requires 2FA` }
              }
            }
            else {
              res = {
                id: request.id,
                result: {"prepare": "okay", 'chunkSize': 250}
              }
            }
          }
          else if(request.method === "sound.prepareState") {
            res = {
              id: request.id,
              result: {"state": "done"}
            }
          }
          else if(request.method === "sound.sendChunk") {
            res = {
              id: request.id,
              result: {"state": "okay"}
            }
          }
          else if(request.method === "blx.getAuthState") {
            res = {
              id: request.id,
              result: {
                "needsPin": false,
                "needsPinSetup": (this.pin === null || this.pin?.length < 2),
                "2fa": true,
                "remainingPinAttempts": this.remainingPinAttempts,
              }
            }
          }
          else if(request.method === "blx.setPin") {
            this.pin = request.params.pin
            res = {
              id: request.id,
              result: {}
            }
          }
          else if(request.method === "blx.getErrorFlag") {
            res = {
              id: request.id,
              result: {errorFlag: 0}
            }
          }
          else if(request.method === "blx.getExtendedErrorRam") {
            res = {
              id: request.id,
              result: [[0,0], [0,1], [0,0]]
            }
          }
          else if(request.method === "blx.getTimerAudio") {
            res = { id: request.id, result: {value: this.timerAudio} } 
          }
          else if(request.method === "blx.setTimerAudio") {
            this.timerAudio = request.params.value
            res = { id: request.id, result: {value: this.timerAudio} } 
          }
          else if(request.method === "blx.getTimerMagnet") {
            res = { id: request.id, result: {value: this.timerMagnet} } 
          }
          else if(request.method === "blx.setTimerMagnet") {
            this.timerMagnet = request.params.value
            res = { id: request.id, result: {value: this.timerMagnet} } 
          }
          else if(request.method === "blx.getWalkTone") {
            res = { id: request.id, result: {walkTone: this.walkTone} } 
          }
          else if(request.method === "blx.setWalkTone") {
            this.walkTone = request.params.walkTone
            res = { id: request.id, result: {} } 
          }
          else if(this.uri.includes("blx.hk") && request.method === "blx.setVoiceMessageOrder") {
            this.voiceMessageOrder = request.params
            res = { id: request.id, result: {} } 
          }
          else if(this.uri.includes("blx.hk") && request.method === "blx.getVoiceMessageOrder") {
            res = { id: request.id, result: this.voiceMessageOrder } 
          }
          else if(this.uri.includes("blx.hk") && request.method === "blx.setSpeakerConfiguration") {
            this.speakerConfiguration = request.params
            res = { id: request.id, result: {} } 
          }
          else if(this.uri.includes("blx.hk") && request.method === "blx.getSpeakerConfiguration") {
            res = { id: request.id, result: this.speakerConfiguration } 
          }
          else if(this.uri.includes("blx.hk") && request.method === "blx.setSpeakerConfiguration") {
            this.speakerConfiguration = request.params
            res = { id: request.id, result: {} } 
          }
          else if(this.uri.includes("blx.hk") && request.method === "blx.getSpeakerConfiguration") {
            res = { id: request.id, result: this.speakerConfiguration } 
          }
          else if(this.uri.includes("blx.hk") && request.method === "blx.setSpeakerGain") {
            /*
              0x00: PT/SPM Front Speaker
              0x01: FT Front Speaker
              0x02: PT/SPM Side Speaker
              0x03: FT Side Speaker
              0x04: Switch Tone Variant
            */

            const speakerIdx = request.params.speaker
            const gain = request.params.gain
            if(speakerIdx < 0 || speakerIdx > 3) {
              res = {
                id: request.id,
                error: { code: -32602, message: `Invalid speaker index` }
              }
            }
            else if(gain < 0 || gain > 15) {
              res = {
                id: request.id,
                error: { code: -32602, message: `Invalid gain` }
              }
            }
            else {
              this.speakerGains[speakerIdx] = gain
              res = { id: request.id, result: {} } 
            }
          }
          else if(this.uri.includes("blx.hk") && request.method === "blx.getSpeakerGain") {
            const speakerIdx = request.params.speaker
            res = {
              id: request.id,
              result: this.speakerGains[speakerIdx]
            }
          }
          else if(this.uri.includes("blx.hk") && request.method === "blx.setPitLedBrightness") {
            const mode = request.params.mode
            if(mode !== "day" && mode !== "night") {
              res = {
                id: request.id,
                error: { code: -32602, message: `Missing/invalid parameter: mode` }
              }
            }

            this.pitLedBrightness[request.params.mode] = request.params.brightness
            res = { id: request.id, result: {} } 
          }
          else if(this.uri.includes("blx.hk") && request.method === "blx.getPitLedBrightness") {
            const mode = request.params.mode
            if(mode !== "day" && mode !== "night") {
              res = {
                id: request.id,
                error: { code: -32602, message: `Missing/invalid parameter: mode` }
              }
            }
            res = { id: request.id, result: this.pitLedBrightness[request.params.mode] } 
          }
          else if(this.uri.includes("blx.hk") && request.method === "blx.getApsGain") {
            res = { id: request.id, result: this.apsGain }
          }
          else if(this.uri.includes("blx.hk") && request.method === "blx.setApsGain") {
            this.apsGain = request.params
            res = { id: request.id, result: {} }
          }
          else if(this.uri.includes("blx.hk") && request.method === "blx.getSwitchToneVariant") {
            res = { id: request.id, result: this.switchToneVariant }
          }
          else if(this.uri.includes("blx.hk") && request.method === "blx.setSwitchToneVariant") {
            const v = request.params
            if(v === undefined || v < 0 || v > 2) {
              res = {
                id: request.id,
                error: { code: -32602, message: `Invalid parameter` }
              }
            }
            else {
              this.switchToneVariant = v
              res = { id: request.id, result: {} }
            }
          }
          
          let responseData = rfc7464Encode(JSON.stringify(res))
          this.onReceive(responseData);
        }
        else {
          console.error("no receive callback")
        }
      }, this.commandDelayMS);

      resolve();
    });
  }
}
