import * as React from 'react';
import { Alert } from 'react-native';
import { useAppSettings } from '../../common/useAppSettings';
import { SwitchListItem, useStatusBarActivity } from '../../ui/RTBUI';
// import { JsonRpcTimeout } from '../protocol/BLXLocidDevice';

export function BLXSpecialBitSwitchListItem({ register, bitIndex, invert=false, specialBits, specialBitsWrite, ...props }) {
  const [value, setValue] = React.useState(false);
  const { setActivity } = useStatusBarActivity();

  const {appSettings} = useAppSettings()

  const writeValue = async(val) => {
    const oldValue = value
    setValue(val);

    setActivity(true);
    try {
      let writeVal = val
      if(invert)
        writeVal = !writeVal

      let newBits = [...specialBits];
      if (writeVal)
        newBits[register] |= 1 << bitIndex;
      else
        newBits[register] &= ~(1 << bitIndex);

      console.log("new bits: ", newBits);
      await specialBitsWrite(newBits);
    }
    catch(err) {
      console.error(err);
      console.log("error type:", typeof err)

      // if(err instanceof JsonRpcTimeout) {
      //   console.log("is timeout!");
      //   Alert.alert("Device error", "Jsonrpc timeout after special bit write (or invalid jsonrpc response from device)");
      // }
      // else {
      //   Alert.alert("Device error", "Unknown error writing special bits")
      // }
      
      setValue(oldValue)
    }
    finally {
      setActivity(false);
    }
  };

  React.useEffect(() => {
    console.log("BLXSpecialBitToggle useEffect specialBits");
    if(specialBits) {
      let isSet = (specialBits[register] & (1 << bitIndex)) !== 0
      if(invert)
        isSet = !isSet
      setValue(isSet);
    }
  }, [specialBits]);

  return (
    <SwitchListItem
      // containerStyle={{ height: 50 }}
      value={value}
      onValueChange={val => writeValue(val)}
      bottomDivider
      {...props} 
      subtitle={appSettings?.developerMode ? props.subtitle : undefined }
      // disabled={specialBits === null}
    />
  );
}
