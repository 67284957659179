import React, { useEffect, useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, InputLabel, MenuItem, Select, Slider, TextField } from '@material-ui/core';
import AudioPlayIcon from '@material-ui/icons/PlayCircleFilledOutlined';
import AudioPauseIcon from '@material-ui/icons/PauseCircleFilledOutlined';
import SaveIcon from '@material-ui/icons/Save';
import { Alert } from './screens/ui/Alert';
import { API_BASE_URL } from './App';

export function AudioSynthDialog({ currentPrefix, onClose }) {
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const [synthText, setSynthText] = React.useState(null);
  const [synthLanguage, setSynthLanguage] = React.useState(null);
  const [synthVoiceName, setSynthVoiceName] = React.useState("default");
  const audioRef = React.useRef(null);
  const [audioPlaying, setAudioPlaying] = React.useState(false);
  const [availableVoices, setAvailableVoices] = React.useState({}) 

  const [speakingRate, setSpeakingRate] = React.useState(1);
  const [pitch, setPitch] = React.useState(0);
  const [gain, setGain] = React.useState(0);
  const [effectProfile, setEffectProfile] = React.useState(null);

  const [filename, setFilename] = React.useState(null);
  const [filenameError, setFilenameError] = React.useState(null);

  const blobRef = React.useRef(null);

  const handleDialogCloseClick = () => {
    setDialogOpen(false)
    setSynthText(null)
    setFilename(null)
    onClose(null)
  };

  const handleDialogSaveClick = async() => {
    await save();
    setDialogOpen(false)
    setSynthText(null)
    setFilename(null)
    onClose()
  };

  const displayNameForLanguageCode = (lc) => {
    const names = new Intl.DisplayNames(["en"], {type: 'language'});
    return names.of(lc);
  }

  const loadAvailableVoices = async() => {
    const res = await axios.get(`${API_BASE_URL}/synth/voices`);
    console.log("voices res", res, res.data);
    setAvailableVoices(res.data);

    // find best matching default lang
    let bestLc = "en-US";
    for(const lc of navigator.languages) {
      if(res.data.language_codes.includes(lc)) {
        console.log("found best lc:", lc, "in", navigator.languages)
        bestLc = lc;
        break;
      }
    }
    setSynthLanguage(bestLc);
  }

  const voicesByLanguageCode = (lc) => {
    if(availableVoices.voices === undefined) {
      return []
    }

    let res = [];
    for(const voice of availableVoices.voices) {
      for(const voiceLc of voice.language_codes) {
        if(lc == null || voiceLc.includes(lc)) {
          res.push(voice.name);
        }
      }
    }

    return res.sort().sort((a,b) => { 
      if(a.includes("Wavenet") && !b.includes("Wavenet"))
        return -1;
      else if(!a.includes("Wavenet") && b.includes("Wavenet"))
        return 1;
      else
        return 0;
    });
  }

  React.useEffect(() => {
    loadAvailableVoices()
  }, []);

  React.useEffect(() => {
    console.log("synthLanguage useEffect");
    setSynthVoiceName("default");
  }, [synthLanguage])

  const synth = async () => {
    const res = await axios.post(`${API_BASE_URL}/synth`,
      {
        text: synthText,
        language: synthLanguage,
        voiceName: synthVoiceName,
        pitch: pitch,
        speakingRate: speakingRate,
        gain: gain,
        effect: effectProfile
      },
      {
        responseType: 'blob'
      }
    );
    console.log("synth res:", res);
    //console.log("synth res:", res.data)
    const blob = new Blob([res.data]);
    blobRef.current = blob;
    const blobUrl = window.URL.createObjectURL(blob);
    console.log("blobUrl", blobUrl);

    audioRef.current.src = blobUrl;
  };

  const resetSettings = async () => {
    setSpeakingRate(1);
    setPitch(0);
    setGain(0);
    setEffectProfile(null);
    setSynthVoiceName("default")
  };

  const play = async () => {
    audioRef.current.play();
  };

  const synthAndPlay = async() => {
    await synth();
    await play();
  }

  const save = async () => {
    if(!filename || filename.length < 0) {
      setFilenameError("Enter file name")
    }

    await synth();

    if (!currentPrefix || !currentPrefix.startsWith("/sounds/")) {
      Alert.alert("Error", `Please navigate to a sound folder first! (prefix: ${currentPrefix})`);
      return;
    }

    let audioData = blobRef.current;

    // convert to wav
    if (true) {
      let convertFormData = new FormData();
      convertFormData.append('file', audioData);

      const convertRes = await axios.post(
        `${API_BASE_URL}/audio/convert`,
        convertFormData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          responseType: 'blob'
        }
      );
      console.log("audio convert res:", convertRes);
      audioData = convertRes.data;
    }

    // upload
    let wavFormData = new FormData();
    wavFormData.append('file', audioData);

    let fname = filename ?? `${synthText}`;
    fname = fname.replace('/', '_').replace('?', '');
    if (!fname.endsWith('.wav'))
      fname = fname + '.wav';
    //if (!fname.endsWith('.mp3'))
    //  fname = fname + '.mp3';

    const uploadRes = await axios.post(
      `${API_BASE_URL}/fs/files/${currentPrefix}/${fname}`,
      wavFormData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    console.log("wav upload res:", uploadRes);

    onClose();
  };


  // autoplay on settings change
  const autoplayTimerRef = React.useRef()
  React.useEffect(() => {
    if(synthVoiceName && (synthText ?? '').length > 0) {
      if(autoplayTimerRef.current) {
        clearTimeout(autoplayTimerRef.current)
      }
      autoplayTimerRef.current = setTimeout(synthAndPlay, 500);
    }

  }, [synthVoiceName, synthLanguage, pitch, gain, speakingRate, effectProfile]);

  return (<>
    <Button
      onClick={() => setDialogOpen(true)}
      color="primary"
      variant="contained"
    >
      Generate Soundfile
    </Button>

    <Dialog open={dialogOpen} onClose={handleDialogCloseClick} aria-labelledby="form-dialog-title"
      maxWidth="sm" 
      fullWidth={true}
      >
      <DialogTitle id="form-dialog-title">Generate Soundfile</DialogTitle>
      <Divider />

    <DialogContent>

    <Box m={1} >
      {/* <Typography variant="h5" component="h2" style={{ marginBottom: 30 }}>
        Synth test (google tts)
      </Typography> */}

      <div style={{display: "flex", marginTop: 20}}>
      <TextField
        placeholder="Text"
        value={synthText}
        onChange={evt => setSynthText(evt.target.value)}
        fullWidth={true}
        autoFocus
        // style={{ width: 500 }}
        onKeyDown={async (event) => {
          if (event.keyCode === 13) {
            console.log("enter!");
            await synthAndPlay();
          }
        }} />

      <Button
        color="secondary"
        variant="contained"
        startIcon={audioPlaying ? <AudioPauseIcon /> : <AudioPlayIcon />}
        style={{ marginLeft: 10 }}
        disabled={(synthText?.length ?? 0) === 0}
        onClick={async () => {
          await synthAndPlay();
        }}
        >
        Preview
        </Button>
      </div>

      <Divider style={{ marginTop: 25, marginBottom: 25 }} />

      <div style={{ marginBottom: 20 }}>
        <InputLabel id="language">
          Language:
       </InputLabel>

        <Select
          style={{ marginTop: 6, marginBottom: 15 }}
          labelId="language"
          id="language-select"
          value={synthLanguage}
          onChange={evt => setSynthLanguage(evt.target.value)}
        >
          {(availableVoices.language_codes ?? []).sort().map((lc, index) => (
            <MenuItem value={lc} key={lc}>{displayNameForLanguageCode(lc)}</MenuItem>
          ))}
        </Select>

        <InputLabel id="voice">
          Voice:
        </InputLabel>

        <Select
          labelId="voice"
          style={{ marginTop: 6, marginBottom: 15 }}
          id="voice-select"
          value={synthVoiceName}
          onChange={evt => setSynthVoiceName(evt.target.value)}
        >
          <MenuItem value={'default'}>Default</MenuItem>

          {voicesByLanguageCode(synthLanguage).map((voiceName, index) => (
            <MenuItem value={voiceName} key={voiceName}>{voiceName}</MenuItem>
          ))}
          
        </Select>
      </div>

      {/* <div style={{ marginBottom: 30, marginTop: 30 }}>
        <InputLabel id="effect">
          Effect Profile:
       </InputLabel>

        <Select
          labelId="effect"
          style={{ marginTop: 6 }}
          value={effectProfile}
          onChange={evt => setEffectProfile(evt.target.value)}
        >
          <MenuItem value={null}>Default</MenuItem>
          <MenuItem value={'wearable-class-device'}>Wearable / Smart watch</MenuItem>
          <MenuItem value={'handset-class-device'}>Smartphones</MenuItem>
          <MenuItem value={'headphone-class-device'}>Headset</MenuItem>
          <MenuItem value={'small-bluetooth-speaker-class-device'}>Small speaker</MenuItem>
          <MenuItem value={'medium-bluetooth-speaker-class-device'}>Medium speaker</MenuItem>
          <MenuItem value={'large-home-entertainment-class-device'}>Hifi</MenuItem>
          <MenuItem value={'large-automotive-class-device'}>Car speaker</MenuItem>
          <MenuItem value={'telephony-class-application'}>Telephony</MenuItem>
        </Select>
      </div> */}

      <div style={{ marginTop: 10 }}>
        <InputLabel>
          Pitch:
        </InputLabel>

        <Slider
          defaultValue={0}
          value={pitch}
          onChange={(evt, newValue) => setPitch(newValue)}
          min={-20}
          max={20}
          valueLabelDisplay="auto" />
      </div>

      <div style={{ marginTop: 10 }}>
        <InputLabel>
          Rate:
        </InputLabel>
        <Slider
          defaultValue={0}
          value={speakingRate}
          onChange={(evt, newValue) => setSpeakingRate(newValue)}
          min={0.25}
          max={4.0}
          step={0.1}
          valueLabelDisplay="auto" />
      </div>

      <div style={{ marginTop: 10 }}>
        <InputLabel>
          Gain:
        </InputLabel>
        <Slider
          defaultValue={0}
          value={gain}
          onChange={(evt, newValue) => setGain(newValue)}
          min={-20}
          max={16}
          valueLabelDisplay="auto" />
      </div>


      <Button
        onClick={resetSettings}
      >reset defaults</Button>

      <Divider style={{ marginTop: 25, marginBottom: 25 }} />

        <TextField
          label="Filename"
          placeholder={synthText ? `${synthText}.wav` : "filename.wav"}
          value={filename}
          onChange={evt => setFilename(evt.target.value)}
          style={{ width: 500 }} />
        {/* <Button
          color="primary"
          variant="contained"
          startIcon={<SaveIcon />}
          style={{ marginLeft: 10 }}
          disabled={(synthText?.length ?? 0) === 0}
          onClick={() => {
            save();
          }}
        >Save</Button> */}

      <audio
        ref={audioRef}
        id="audio-elem"
        type="audio/mp3"
        src=""
        onPlay={() => { setAudioPlaying(true); }}
        onEnded={() => { setAudioPlaying(false); }}
        onPause={() => { setAudioPlaying(false); }} />

    </Box>
    </DialogContent>


    <DialogActions>
      <Button onClick={handleDialogCloseClick} color="gray">
        Cancel
      </Button>
      <Button 
        disabled={(synthText?.length ?? 0) === 0}
        onClick={handleDialogSaveClick} 
        color="primary">
        Save
      </Button>
    </DialogActions>
  </Dialog>
  </>);
}
