import * as React from 'react';
import Slider from '@react-native-community/slider';
import { useTranslation } from 'react-i18next';
import { Alert, View } from "react-native";
import { Divider, Text, Icon } from "react-native-elements";
import Colors from '../../constants/Colors';
import { useDevice } from '../common/DeviceContext';
import { NumericInputItem, SectionHeader, Spacer, SwitchListItem, useStatusBarActivity } from '../ui/RTBUI';
import { DebugView } from './common/DebugView';
import { MultiSliderDynamicWidth } from './common/MultiSlider';
import { BLXScreenContainer } from './BLXScreenContainer';
import { SelectItem } from '../SelectItem';
import { Platform } from 'react-native';
import { SliderDynamicWidth } from './common/Slider';

export function BLXToneProfileScreen({ route }) {
  const device = useDevice();
  const [profile, setProfile] = React.useState();
  const [gain, setGain] = React.useState(0.5);
  const [minLevel, setMinLevel] = React.useState(30);
  const [maxLevel, setMaxLevel] = React.useState(90);
  const { setActivity } = useStatusBarActivity();
  const { t } = useTranslation();

  const gainSliderTimerRef = React.useRef(null);

  const mode = route.params.mode; // day / night
  const tone = route.params.tone; // FT / PT // SPM

  console.log("TONE PROFILE mode:", mode, tone, "route:", route)

  const load = async () => {
    setActivity(true);
    console.log("GET PROFILE!!!");

    try {
      let prof = await device.jsonRpc("blx.getAudioProfile", { "mode": mode, "tone": tone });
      setProfile(prof);
      setMinLevel(prof.minValue);
      setMaxLevel(prof.maxValue);
      setGain(prof.gain);
      return prof;
    }
    catch (error) {
      console.error("error on setAudioProfile:", error);
      Alert.alert(t("Device error"), error.message);
    }
    finally {
      setActivity(false);
    }
  };

  React.useEffect(() => {
    if (device)
      load();

    return () => {
      if (gainSliderTimerRef.current)
        clearTimeout(gainSliderTimerRef.current);
    };
  }, [device]);

  // React.useEffect(() => {
  //   console.log("profile changed, write to device");
  //   if(profile) {
  //     setActivity(true)
  //     setTimeout(async() => {
  //       // const muteRes = await device.jsonRpc("blx.muteAudioProfile", {"mode": mode, "tone": tone})
  //       // console.log("muteRes:", muteRes)
  //       try {
  //         let res = await device.jsonRpc("blx.setAudioProfile", profile)
  //         console.log("blx.setAudioProfile res:", res)
  //       }
  //       catch(error) {
  //         Alert.alert("Device error", error.message)
  //       }
  //       // setProfile(prof)
  //       setActivity(false)
  //     }, 100)
  //   }
  // }, [profile])

  React.useEffect(() => {
    console.log("BLXToneProfileScreen profile changed:", profile);
  }, [profile]);

  const onGainSliderValueChanged = (val) => {
    console.log("onGainSliderValueChanged: ", val);
    // setGain(val)
  };

  const onGainSliderSlidingComplete = (val) => {
    console.log("onGainSliderSlidingComplete: ", val);

    if (gainSliderTimerRef.current) {
      clearTimeout(gainSliderTimerRef.current);
      gainSliderTimerRef.current = null;
    }

    gainSliderTimerRef.current = setTimeout(async () => {
      try {
        await sendProfileUpdate({ "gain": val });
      }
      catch (err) {
        Alert.alert(t("Device error"), err.message);
      }
    }, 100);
  };

  // if(!profile) {
  //   return (
  //     <View>
  //       <ActivityIndicator />
  //     </View>
  //   )
  // }
  // rounds min/max value entries to 5-steps (32->30)
  const inputValueTransformRoundTo5 = (val) => {
    console.log("inputValueTransformRoundTo5: ", val, "res: ", Math.round(val / 5) * 5);
    return Math.round(val / 5) * 5;
  };

  const inputValueTransformRoundToDecimal5 = (val) => {
    return Math.round(val / 0.5) * 0.5;
  };

  const sendProfileUpdate = async (update) => {
    const newProf = {
      ...profile,
      ...update
    };
    console.log("newProf:", newProf);

    setActivity(true);

    try {
      let res = await device.jsonRpc("blx.setAudioProfile", newProf);
      console.log("blx.setAudioProfile res:", res);
    }
    catch (error) {
      console.error("eror on setAudioProfile:", error);
      Alert.alert(t("Device error"), error.message);
    }

    // setTimeout(load, 100)
    await load();

    // todo: check if successfull
    setActivity(false);
  };

  const onMinMaxSliderValuesChange = async (vals) => {
    console.log("minmaxslidervals cahnged:", vals);

    const oldMin = minLevel;
    const oldMax = maxLevel;

    const newMin = vals[0];
    const newMax = vals[1];

    setMinLevel(vals[0]);
    setMaxLevel(vals[1]);

    if (gainSliderTimerRef.current) {
      clearTimeout(gainSliderTimerRef.current);
      gainSliderTimerRef.current = null;
    }

    gainSliderTimerRef.current = setTimeout(async () => {
      try {
        await sendProfileUpdate({ minValue: newMin, maxValue: newMax });
      }
      catch (err) {
        console.error(err);
        setMinLevel(oldMin);
        setMaxLevel(oldMax);
      }
      finally {
        gainSliderTimerRef.current = null;
      }
    }, 100);
  };

  return (
    <BLXScreenContainer onReload={load}>

      {/* mute setting is only available for PT */}
      {(tone === 'PT' || tone === 'SPM') && (<>
        <Spacer />
        <SwitchListItem
          title={t("Mute")}
          subtitle={t("Disable sound output")}
          value={profile?.muted}
          //onValueChange={(val) => setProfile(s => { return {...s, muted: val} } )}
          onValueChange={(val) => sendProfileUpdate({ "muted": val })}
          bottomDivider
          topDivider 
          />
      </>)}

      {tone === 'SPM' && <>
        <Text style={{color: '#f08400', margin: 10}}>
          Nice to have: Für mute auf day/night-Ebene gibt es aktuell keinen Befehl. Könnte man wahrscheinlich relativ einfach über ein byte (0xFF o.ä.)
          in 57: CMD_SET_VOLUME_SPM einbauen.
        </Text>
      </>}

      <SectionHeader>
        {t("Gain")}
      </SectionHeader>

      {/* see: https://github.com/react-native-elements/react-native-elements/blob/next/src/config/colors.tsx */}
      <Divider height={0.5} color="rgba(0, 0, 0, 0.12)" />

      {true && (<>
        <View style={{
          flex: 1, backgroundColor: 'white', flexDirection: 'row', width: '100%', justifyContent: 'center', marginTop: 0, marginBottom: 0,
          opacity: (profile?.muted) ? 0.5 : 1, height: 50
        }}>

          <View style={{ backgroundColor: 'transparent', alignSelf: 'center', width: 32, marginLeft: 10 }}>
            <Icon
              name='volume-down'
              type="font-awesome-5"
              size={25}
              color='grey' />
          </View>

          <View style={{ backgroundColor: 'transparent', flexGrow: 1, alignSelf: 'center', marginLeft: 15, marginRight: 15 }}>
            <SliderDynamicWidth
              allowTouchTrack
              style={{ marginLeft: 0, marginRight: 0, marginTop: 0, height: 50 }}
              value={gain}
              onValueChange={onGainSliderValueChanged}
              onSlidingComplete={onGainSliderSlidingComplete}
              onSlidingStart={() => {
                console.log("onSlidingStart")
              }}
              minimumValue={0.5}
              maximumValue={15.0}
              step={0.5}
              tapToSeek={true}
              // maximumTrackTintColor='#494A48' 
              minimumTrackTintColor='orange'
              tintColor='orange'
              thumbTintColor={Colors.tintColor}
              disabled={profile?.muted} />
          </View>

          <View style={{ backgroundColor: 'transparent', alignSelf: 'center', width: 32, marginRight: 12, marginLeft: 2 }}>
            <Icon
              name='volume-up'
              type="font-awesome-5"
              size={25}
              style={{ width: '100%' }}
              color='grey' />
          </View>
        </View>
      </>)}

      <NumericInputItem
        title={t("Gain")}
        valueType="float"
        value={profile?.gain}
        // onValueSubmit={(val) => setProfile(s => { return {...s, gain: val} } )}
        onValueSubmit={(val) => sendProfileUpdate({ "gain": val })}
        inputValueTransform={inputValueTransformRoundToDecimal5}
        minValue={0.5}
        maxValue={15}
        placeholder="0.5"
        unit="dB"
        disabled={profile?.muted}
        topDivider
        bottomDivider />

      <SectionHeader>
        {t('Gain thresholds')}
      </SectionHeader>

      <Divider height={0.5} color="rgba(0, 0, 0, 0.12)" />

      <View style={{
        flex: 1, backgroundColor: 'white', flexDirection: 'row', width: '100%', justifyContent: 'center',
        paddingTop: 1,
        opacity: (profile?.muted) ? 0.5 : 1
      }}>

        <View style={{ backgroundColor: 'transparent', alignSelf: 'center', width: 32, marginLeft: 10 }}>
          <Icon
            name='volume-down'
            type="font-awesome-5"
            size={25}
            color='grey' />
        </View>

        {/* see https://github.com/ptomasroos/react-native-multi-slider/issues/91 to enable larger touch area */}
        <MultiSliderDynamicWidth
          wrapViewProps={{ backgroundColor: 'transparent', flexGrow: 1, alignSelf: 'center', marginLeft: 20, marginRight: 20 }}
          values={[minLevel, maxLevel]}
          min={30}
          max={90}
          step={5}
          // showSteps={true}
          // showStepMarkers={true}
          // enabledOne={true}
          // enabledTwo
          onValuesChange={onMinMaxSliderValuesChange}
          snapped
          // style={{width: 70}}
          // sliderLength={'100%'}
          //trackStyle={{backgroundColor: 'light'}}
          containerStyle={{ height: 50, opacity: (profile === null ? 0 : 1) }}
          trackStyle={{ height: (Platform.OS === 'ios' ? 4 : 1), backgroundColor: '#e3e3e3' }}
          selectedStyle={{ backgroundColor: 'orange' }}
          markerStyle={{ backgroundColor: Colors.tintColor }}
          // markerSize={50}
          // enableLabel={true}
          allowOverlap={true}
          // touchDimensions={{height: 100, width: 100, borderRadius: 40, slipDisplacement: 200}}
          // todo: not working
          enabledOne={!profile?.muted}
          enabledTwo={!profile?.muted} />

        <View style={{ backgroundColor: 'transparent', alignSelf: 'center', width: 32, marginRight: 12, marginLeft: 2 }}>
          <Icon
            name='volume-up'
            type="font-awesome-5"
            size={25}
            style={{ width: '100%' }}
            color='grey' />
        </View>
      </View>

      <NumericInputItem
        title={t("Minimum level")}
        value={profile?.minValue}
        onValueSubmit={(val) => sendProfileUpdate({ "minValue": val })}
        disabled={profile?.muted}
        placeholder="-"
        unit="dB"
        valueType="float"
        minValue={30}
        maxValue={90}
        inputValueTransform={inputValueTransformRoundTo5}
        topDivider
        bottomDivider />
      <NumericInputItem
        title={t("Maximum level")}
        value={profile?.maxValue}
        onValueSubmit={(val) => sendProfileUpdate({ "maxValue": val })}
        disabled={profile?.muted}
        placeholder="-"
        unit="dB"
        minValue={30}
        maxValue={90}
        inputValueTransform={inputValueTransformRoundTo5}
        bottomDivider />

      <Spacer />

      <SelectItem
        title={t("Drop speed")}
        value={profile?.dropSpeed}
        //onValueChange={(val) => setProfile(p => ({ ...p, "dropSpeed": val })) }
        onValueChange={(val) => sendProfileUpdate({ "dropSpeed": val })}
        items={[
          { label: t('normal'), value: 'normal' },
          { label: t('fast'), value: 'fast' },
          { label: t('slow'), value: 'slow' },
          { label: t('off'), value: 'off' },
        ]}
        topDivider
        bottomDivider
        disabled={profile?.muted}
        style={{ height: 50 }} />

      <DebugView>
        <Spacer />
        <Text>DEBUG:</Text>
        <Text>mode: {mode}, tone: {tone}, profile: {JSON.stringify(profile)}</Text>
        <Text>min: {minLevel}, max: {maxLevel}</Text>
      </DebugView>
    </BLXScreenContainer>
  );
}
