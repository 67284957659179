import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@material-ui/core';
import axios from 'axios';
import React from 'react';
import { AuthContext } from './App';
import { MapContainer, TileLayer, Marker, Popup, Circle } from 'react-leaflet'

function LocIdDebugDeviceDialog({devId, onClose}) {
  const [state, setState] = React.useState({})
  const [debugText, setDebugText] = React.useState('')

  const [latLng, setLatLng] = React.useState([0,0])
  const [anchors, setAnchors] = React.useState([])

  const timerRef = React.useRef()
  const mapRef = React.useRef()

  const loadState = async() => {
    const res = await axios.get(`https://locid.blx.rtb-bl.de/devices/${devId}/last_state`, {
      responseType: 'json'
    });
    console.log("load getRes:", res);
    setState(res.data);

    const lat = res.data?.ev?.data?.loc?.lat;
    const lng = res.data?.ev?.data?.loc?.lng;
    if(lat && lng) {
      setLatLng([lat, lng])
      mapRef.current?.panTo([lat, lng])
    }
  };

  const loadDebugText = async() => {
    const res = await axios.get(`https://locid.blx.rtb-bl.de/devices/${devId}/last_debug_text`);
    console.log("loadDebugText getRes:", res);
    setDebugText(res.data);
  }

  const loadAnchors = async() => {
    const res = await axios.get(`https://locid.blx.rtb-bl.de/anchors`);
    console.log("loadDebugText getRes:", res);
    setAnchors(res.data?.anchors ?? []);
  }

  React.useEffect(() => {
    if(devId) {
      loadState()
      loadDebugText()
      loadAnchors()

      timerRef.current = window.setInterval(() => {
        loadState()
        loadDebugText()
      }, 1000)
    }

    return () => {
      window.clearInterval(timerRef.current)
    }
  }, [devId])

  React.useEffect(() => {
  }, [mapRef])

  return (
    <Dialog 
      open={devId !== null}
      onClose={onClose}
      maxWidth="md"
      >
      <DialogTitle>{devId}</DialogTitle>

      <DialogContent dividers>

        <MapContainer 
          center={latLng}
          height={300}
          width={900}
          zoom={18} 
          scrollWheelZoom={true} 
          style={{height: 300, width: 900}}
          whenCreated={ mapInstance => { 
            mapRef.current = mapInstance 
          }}
          attributionControl={false}
          >

          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={latLng}>
            <Popup>
              {devId ?? ''}
            </Popup>
          </Marker>

          {anchors.map((a, idx) => {
            <Circle 
              center={[a.lat, a.lng]}
              weight={1}
              opacity={0.3}
              fillColor="#f03"
              fillOpacity={0.3}
              radius={6}
            />
          })}

        </MapContainer>

        <pre style={{fontSize: 12}}>
          {debugText ?? ''}
        </pre>
        <Divider />
        State:
        <pre style={{fontSize: 12}}>
          {state ? JSON.stringify(state, null, 4) : ''}
        </pre>

      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function LocIdDebugScreen() {

  const [devs, setDevs] = React.useState([]);
  const { auth } = React.useContext(AuthContext);
  const [selectedDev, setSelectedDev] = React.useState(null);

  const load = async () => {
    console.log("LOAD!");
    const res = await axios.get(`https://locid.blx.rtb-bl.de/devices`, {
      responseType: 'json'
    });
    console.log("getRes:", res);
    setDevs(res.data);
  };

  React.useEffect(() => {
    load();
  }, [auth]);

  return (<>
    <LocIdDebugDeviceDialog 
      devId={selectedDev} 
      onClose={() => setSelectedDev(null)}
      />

    {/* <Button
      onClick={load}
    >lid</Button> */}

    <table border={1} style={{marginTop: 30, borderCollapse: 'collapse'}}>
      <tbody>
      {devs.map((d, i) => {
        return (
          <tr key={i}>
            <td>
              <a href='#' onClick={(evt) => {
                evt.preventDefault()
                setSelectedDev(d.id)
              }}>
                {d.id}
              </a>
            </td>
            <td>{d.model}</td>
            <td>{new Date(d.t * 1000).toLocaleString() }</td>
          </tr>
        );
      })}
      </tbody>
    </table>
  </>);
}
