import * as React from 'react';
import { Alert, View } from "react-native";
import { DeviceContext } from '../common/DeviceContext';
import { SectionHeader, useStatusBarActivity } from '../ui/RTBUI';
import { BLXSpecialBitSwitchListItem } from './common/BLXSpecialBitSwitchListItem';
import { useBlxSpecialBits } from './protocol/useBlxSpecialBits';
import { BLXScreenContainer } from './BLXScreenContainer';


export function BLXSpecialBitScreen({ route }) {
  const { device } = React.useContext(DeviceContext);
  const register = route.params.register;
  const { setActivity } = useStatusBarActivity();
  const { specialBits, specialBitsRead, specialBitsWrite } = useBlxSpecialBits(device);

  let registerIndex;
  if (register === 'A')
    registerIndex = 0;
  else if (register === 'B')
    registerIndex = 1;
  else if (register === 'C')
    registerIndex = 2;

  const onLoad = async () => {
    // await device.ensureConnected()
    // console.log("connected")
    setActivity(true);

    if (device) {
      try {
        await specialBitsRead();
      }
      catch (error) {
        Alert.alert("Device error", error.message);
      }
    }
    setActivity(false);
  };

  React.useEffect(() => {
    if (device) {
      onLoad();
    }
    return () => { };
  }, [register, device]);

  return (
    <BLXScreenContainer onReload={onLoad}>
      <SectionHeader>
        Register {register}
      </SectionHeader>
      {[...Array(16)].map((e, index) => {
        return (
          <BLXSpecialBitSwitchListItem
            title={register + " " + index}
            key={index}
            containerStyle={{ minHeight: 50 }}
            specialBits={specialBits}
            specialBitsWrite={specialBitsWrite}
            register={registerIndex}
            bitIndex={index}
            bottomDivider
            topDivider={index === 0} />
        );
      })}

      <View style={{ height: 20 }} />
    </BLXScreenContainer>
  );
}
