import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from "react-native-elements";
import { DeviceContext } from '../common/DeviceContext';
import { DescriptionListItem, SectionHeader, Spacer } from '../ui/RTBUI';
import { BLXSpecialBitMultiSelectListItem } from './common/BLXSpecialBitMultiSelectListItem';
import { BLXSpecialBitSwitchListItem } from './common/BLXSpecialBitSwitchListItem';
import { BLXDebugSpecialBits, DebugView } from './common/DebugView';
import { useBlxSpecialBits } from './protocol/useBlxSpecialBits';
import { BLXScreenContainer } from './BLXScreenContainer';


export function BLXPedSettingsScreen() {
  const { device } = React.useContext(DeviceContext);
  const { specialBits, specialBitsRead, specialBitsWrite } = useBlxSpecialBits(device);
  const { t } = useTranslation();

  const load = async () => {
    await specialBitsRead();
  };

  React.useEffect(() => {
    if (device)
      load();
  }, [device]);

  return (
    <BLXScreenContainer onReload={load}>

      <SectionHeader>
        {t('Feedback')}
      </SectionHeader>

      <BLXSpecialBitSwitchListItem
        title={t("Enable voice announcement")}
        subtitle="A[7]"
        register={0}
        bitIndex={0x7}
        specialBits={specialBits}
        specialBitsWrite={specialBitsWrite}
        topDivider
        bottomDivider />

      <BLXSpecialBitSwitchListItem
        title={t("Beep on button press")}
        subtitle="A[9]"
        register={0}
        bitIndex={0x9}
        specialBits={specialBits}
        specialBitsWrite={specialBitsWrite}
        bottomDivider />

      <SectionHeader>
        APS signalling
      </SectionHeader>
      <BLXSpecialBitSwitchListItem
        title={t("LOC.id to APS")}
        subtitle="B[6]"
        register={1}
        bitIndex={0x6}
        specialBits={specialBits}
        specialBitsWrite={specialBitsWrite}
        topDivider
        bottomDivider />
      <DescriptionListItem
        text={t("If an assistive pedestrian is detected by LOC.id, the PED button issues an APS call.")} />

      <Spacer />
      <BLXSpecialBitMultiSelectListItem
        title={t("Hold to APS")}
        subtitle="B[0-1]"
        specialBits={specialBits}
        specialBitsWrite={specialBitsWrite}
        register={1}
        bitMask={0b11}
        items={[
          { label: t('disabled'), value: 0b00 },
          { label: t('0s'), value: 0b11 },
          { label: t('3s'), value: 0b01 },
          { label: t('6s'), value: 0b10 },
        ]}
        bottomDivider
        topDivider
        />

      <DescriptionListItem
        text={t("Request APS after holding the PED button for some time.")} />

      <DebugView>
        <Spacer />
        <Text>DEBUG:</Text>
        <BLXDebugSpecialBits specialBits={specialBits} />
      </DebugView>

    </BLXScreenContainer>
  );
}
