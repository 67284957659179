import {Buffer} from 'buffer';

/**
 * decodes a netstring "12:{...}" into [len, string]
 * @param data 
 * @returns array of length and string. If data buf is not long enough, undefined
 */
export function netStringDecode(data : Buffer) : string|undefined {
  let str = data.toString()
  let lenIdx = str.indexOf(":")
  let lenStr = str.substring(0, lenIdx)
  let len = parseInt(lenStr)

  if(data.length > lenIdx + len) {
    let payload = data.slice(lenIdx+1, lenIdx+len+1)  
    console.log("payload: ", payload.toString())
    let res = payload.toString('utf8')
    return res
  }
  else {
    return undefined
  }
}

export function netStringEncode(str : string) : Buffer {
  let len = str.length
  str = len.toString() + ":" + str
  return Buffer.from(str, 'utf8')
}

export function rfc7464Encode(data : Buffer|String) : Buffer {
  let rs = Buffer.from('1E', 'hex')
  var jsonBuf : Buffer
  if(data instanceof Buffer) {
    jsonBuf = data
  }
  else if(typeof data === 'string') {
    const dataStr = data as string;
    jsonBuf = Buffer.from(dataStr, 'utf-8')
  }
  else {
    throw new Error("invalid data arg type: " + (typeof data))
  }

  let lf = Buffer.from('0A', 'hex')
  return Buffer.concat([rs, jsonBuf, lf])
}