import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@material-ui/core";

export default function FileNameDialog({open, onClose}) {

  const [filename, setFilename] = React.useState(null)

  const handleClose = () => {
    onClose(null)
  };
  const handleSave = () => {
    onClose(filename)
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" 
        maxWidth="sm"
        fullWidth={true}
        >
        <DialogTitle id="form-dialog-title">Save</DialogTitle>
        <DialogContent dividers>
          {/* <DialogContentText>
            Configuration name
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Configuration Name"
            type="text"
            fullWidth
            onChange={event => setFilename(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="gray">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}