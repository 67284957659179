import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Text } from "react-native-elements";
import { DeviceContext } from '../common/DeviceContext';
import { Spacer } from '../ui/RTBUI';
import { SelectItem } from "../SelectItem";
import { BLXSpecialBitSwitchListItem } from './common/BLXSpecialBitSwitchListItem';
import { BLXDebugSpecialBits, DebugView } from './common/DebugView';
import { useBlxSpecialBits } from './protocol/useBlxSpecialBits';
import { BLXScreenContainer } from './BLXScreenContainer';
import { isset } from '../jsutils';
import { BLXSpecialBitMultiSelectListItem } from './common/BLXSpecialBitMultiSelectListItem';

export function BLXClearanceScreen() {
  const { device } = React.useContext(DeviceContext);
  const { t } = useTranslation();
  const { specialBits, specialBitsRead, specialBitsWrite } = useBlxSpecialBits(device);

  React.useEffect(() => {
    if (device)
      specialBitsRead();
  }, [device]);

  if (!device) {
    return <Text>no device</Text>;
  }

  return (
    <BLXScreenContainer>
      <Spacer />

      <BLXSpecialBitSwitchListItem
        title={t("Clearance indication enabled")}
        subtitle="A[A]"
        specialBits={specialBits}
        specialBitsWrite={specialBitsWrite}
        register={0}
        bitIndex={0xA}
        topDivider
        bottomDivider />

      <Spacer />
      <BLXSpecialBitSwitchListItem
        title={t("Keep locator tone enabled")}
        subtitle="A[F]"
        specialBits={specialBits}
        specialBitsWrite={specialBitsWrite}
        register={0}
        bitIndex={0xF}
        disabled={!isset(specialBits[0], 0xA)}
        topDivider
        bottomDivider 
        />

      {device?.isQuebec() && (
        <>
          <Spacer />
          <BLXSpecialBitMultiSelectListItem
            title={t("Tone variant")}
            subtitle="B[8-9]"
            leftIcon={{ type: "font-awesome-5", color: 'gray', style: { width: 25, textAlign: 'center' }, name: 'music' }}
            specialBits={specialBits}
            specialBitsWrite={specialBitsWrite}
            register={1}
            bitMask={0b1100000000}
            items={[
              { label: t('Countdown'), value: 0b0000000000 },
              { label: t('Canadian Melody'), value: 0b0100000000 },
              { label: t('Cuckoo'), value: 0b1000000000 },
            ]}
            bottomDivider
            topDivider
            disabled={!isset(specialBits[0], 0xA)}
            disabledStyle={{ opacity: 0.5 }}
            />
        </>
      )}

      <Divider height={1} style={{ marginVertical: 30, width: '100%' }} />

      <Text style={{ marginHorizontal: 15, color: 'gray' }}>
        {t("Volume settings are inherited from Walk phase settings.")}
      </Text>

      <DebugView>
        <Spacer />
        <Text>DEBUG:</Text>
        <BLXDebugSpecialBits specialBits={specialBits} />
      </DebugView>

    </BLXScreenContainer>
  );
}
