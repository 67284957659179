import Slider from '@react-native-community/slider';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { View } from 'react-native';
import { ListItem } from 'react-native-elements';
import { LocIdDebugScreen } from './LocIdDebugScreen';
import { NumericInputItem } from './screens/ui/RTBUI';

const Stack = createStackNavigator()

function Screen1() {
  return (
    <div style={{width: 100, height: 100, backgroundColor: "blue"}}>
    Screen1
    </div>
  )
}
function Screen2() {
  return <div>asdf</div>
}

export function TestScreen() {
  return (
    <div>
      <ListItem>
        <ListItem.Content>
          <ListItem.Title>
            title
          </ListItem.Title>
        </ListItem.Content>
        <ListItem.Input 
          onChangeText={(evt) => {
            console.log("changeText!", evt)

          }}
        />
      </ListItem>


      <NumericInputItem 
        title="asdf"
        valueType="float"
      />

      <Slider 
        value={10}
        minimumValue={0}
        maximumValue={100}
        step={0.5}
        allowTouchTrack
        style={{ marginLeft: 0, marginRight: 0, marginTop: 0, width: '100%', height: 50 }}
        tapToSeek={true}
        onSlidingStart={() => {
          console.log("onSlidingStart")
        }}
        onValueChange={() => {
          console.log("onSlidingStart")
        }}
      />

    </div>
    // <View style={{flex: 1, height: 300}}>

    //   <NavigationContainer>
    //     <Stack.Navigator
    //       initialRouteName="screen1"
    //     >
    //       <Stack.Screen 
    //         name="screen1" 
    //         component={Screen1} 
    //         />
    //     </Stack.Navigator>

    //     foobar

    //     <Button
    //     >nav</Button>

    //   </NavigationContainer>
    //   test!
    // </View>
  )
}
