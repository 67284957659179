import { Platform, StyleSheet } from "react-native";

//const tintColor = '#2f95dc';
const tintColor = 'rgb(204,111,21)'; // #cc6f15

export default {
  tintColor,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
  lightText: 'gray',
  disabledBackground: 'gray'
};

export const appStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  sectionHeader: {
    marginLeft: 14,
    marginTop: 24,
    marginBottom: 8,
    // fontWeight: 'bold',
  },
  sectionView: {
    backgroundColor: 'white',
    paddingTop: 10
  }
});

// styles for theming react native elements components
export const rnElementsTheme = {
  Button: {
    // raised: true,
    buttonStyle: {
      backgroundColor: tintColor,
    }
  },
  ListItem: {
    containerStyle: {
      padding: 12,
    },
    titleStyle: {
      color: 'black'
    }
  },
  Switch: {
     trackColor: { true: 'orange', false: Platform.OS=='android' ? '#d3d3d3' : '#fbfbfb' },
  },
  Input: {
    labelStyle: {
      fontWeight: "normal"
    }
  }
}

export const rnElementsThemeLight = {
  ...rnElementsTheme
}

export const rnElementsThemeDark = {
  ...rnElementsTheme,
  Button: {
    buttonStyle: {
      backgroundColor: 'red'
    }
  }
}

// export const RNNavigationDarkTheme = {
//   ...DarkTheme,
//   // dark: true,
//   // colors: {
//   //   primary: 'rgb(255, 45, 85)',
//   //   //background: 'rgb(242, 242, 242)',
//   //   background: 'black',
//   //   card: 'rgb(255, 255, 255)',
//   //   text: 'rgb(28, 28, 30)',
//   //   border: 'rgb(199, 199, 204)',
//   //   notification: 'rgb(255, 69, 58)',
//   // },
// };

// export const RNNavigationLightTheme = {
//   ...DefaultTheme,
// }